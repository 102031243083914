import axios from "../util/axios";

const fetchAllJournals = () => axios.get("journals");

const addJournal = (data) => axios.post("journals/create", data);

const updateJournal = (data, id) => axios.put("journals/" + id, data);

const removeJournal = (id) => axios.delete("journals/" + id);

export default {
  fetchAllJournals,
  addJournal,
  updateJournal,
  removeJournal
};