import {
  Autocomplete,
  Avatar,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import authorsTableData from "layouts/categories/data/authorsTableData";
import productTableData from "layouts/products/data/authorsTableData";

import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import productService from "services/product.service";
import { useMaterialUIController } from "context";
import { Link } from "react-router-dom";
import { setShowLoader } from "context";
import uploadFileService from "services/upload-file.service";
import { setSnackBar } from "context";

import "./upload.css";
import UploadImage from "./uploadProductImage";

const ProductEdit = () => {
  const { options } = authorsTableData();
  const { productIndex } = productTableData();

  const inputFile = useRef(null);
  const [image, setImage] = useState(null);

  const [controller, dispatch] = useMaterialUIController();

  const { products } = controller;
  const { pid } = useParams();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const routeHistory = useHistory();

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: selectedProducts,
  });
  const formValues = getValues();
  console.log({ formValues });
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.value,
  };

  const defaultStatusProps = {
    options: [
      { key: 1, value: "Active" },
      { key: 0, value: "Inactive" },
    ],
    getOptionLabel: (option) => option.value,
  };

  const defaultIndexProps = {
    options: productIndex,
    getOptionLabel: (option) => option.value,
  };

  const [weightValue, setWeightValue] = useState(null);
  const [grossWeightValue, setGrossWeightValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [descVal, setDescVal] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [usage, setUsage] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [showProduct, setShowProduct] = useState(0);
  const [prodIndex, setProdIndex] = useState(null);
  const [Files, SetFiles] = useState([]);
  const [packBoolVal, setPackBoolVal] = useState({});
  const DISCOUNT_REGEX = /^\d+$/;
  const DECIMAL_REGEX = /^\d*\.?\d*$/;
  const [fragnance, setFragnance] = useState(null);
  const [detail, setDetail] = useState(null);

  const fragnancePaste = (evnt) => {
    setFragnance(evnt.editor.getData());
    setValue("fragnance_notes", evnt.editor.getData());
  };
  const fragnanceBlur = (evnt) => {
    setFragnance(evnt.editor.getData());
    setValue("fragnance_notes", evnt.editor.getData());
  };
  const fragnanceChange = (evnt) => {
    setFragnance(evnt.editor.getData());
    setValue("fragnance_notes", evnt.editor.getData());
  };

  const detailPaste = (evnt) => {
    setDetail(evnt.editor.getData());
    setValue("product_details", evnt.editor.getData());
  };
  const detailBlur = (evnt) => {
    setDetail(evnt.editor.getData());
    setValue("product_details", evnt.editor.getData());
  };
  const detailChange = (evnt) => {
    setDetail(evnt.editor.getData());
    setValue("product_details", evnt.editor.getData());
  };

  const ingredientPaste = (evnt) => {
    setIngredients(evnt.editor.getData());
    setValue("ingredients", evnt.editor.getData());
  };
  const ingredientBlur = (evnt) => {
    setIngredients(evnt.editor.getData());
    setValue("ingredients", evnt.editor.getData());
  };
  const ingredientChange = (evnt) => {
    setIngredients(evnt.editor.getData());
    setValue("ingredients", evnt.editor.getData());
  };

  const usagePaste = (evnt) => {
    setUsage(evnt.editor.getData());
    setValue("usage", evnt.editor.getData());
  };
  const usageBlur = (evnt) => {
    setUsage(evnt.editor.getData());
    setValue("usage", evnt.editor.getData());
  };
  const usageChange = (evnt) => {
    setUsage(evnt.editor.getData());
    setValue("usage", evnt.editor.getData());
  };

  const descPaste = (evnt) => {
    setDescVal(evnt.editor.getData());
    setValue("description", evnt.editor.getData());
  };
  const descBlur = (evnt) => {
    setDescVal(evnt.editor.getData());
    setValue("description", evnt.editor.getData());
  };
  const descChange = (evnt) => {
    setDescVal(evnt.editor.getData());
    setValue("description", evnt.editor.getData());
  };

  const handleStatus = (newValue) => {
    setStatusValue(newValue);
    setValue("status", newValue);
  };

  const handleCategoryValue = (newValue) => {
    setCategoryValue(newValue);
    setValue("category_id", newValue);
  };

  const handleShowProduct = (event) => {
    setShowProduct(event.target.value);
    setValue("show_product", event.target.value);
    setProdIndex(undefined);
    setValue("product_index", undefined);
  };

  const handleProductIndex = (val) => {
    setProdIndex(val);
    setValue("product_index", val.key);
  };

  const handleChangeRadio = (event) => {
    console.log({ event });
    setValue(event.target.name, event.target.value);
    setPackBoolVal({
      ...packBoolVal,
      [event.target.name]: event.target.value,
    });
    // reset({
    //   ...formValues,
    //   [event.target.name]: event.target.value,
    // });
  };
  let config = {
    toolbarGroups: [
      { name: "document", groups: ["mode", "document", "doctools"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      "/",
      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },
      { name: "styles", groups: ["styles"] },
      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      "/",
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
    height: 65,
    // disableNativeSpellChecker: false
    // skin: "moono",
    // plugins:
    //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
  };

  const onUploadClick = () => {
    inputFile.current.click();
  };

  const onImageChange = async (event, isMultiple = false) => {
    if (!isMultiple) {
      const files = event.target.files;
      if (files && files[0]) {
        setImage(URL.createObjectURL(files[0]));
        const { name, type } = files[0];
        const params = {
          key: name.includes(".") ? name.substring(0, name.indexOf(".")) : name,
          extension: type.includes("/")
            ? type.substring(type.indexOf("/") + 1)
            : type,
          belongsTo: "product",
          purpose: "product_img",
        };

        try {
          const res = await uploadFileService.getSignedUploadUrl(params);
          const url = res.data.data.signedURL;
          if (url) {
            try {
              const response = await uploadFileService.uploadFile(
                url,
                files[0]
              );
              setValue("image_id", res.data.data.id);
              const reader = new FileReader();
              reader.onload = function (e) {
                // setUploadedFileURLs(uploadedFileURLs);
              };
              reader.onerror = function (e) {};
              reader.readAsDataURL(files[0]);
              let parsedUrl = new URL(url).pathname.substring(1);
              parsedUrl = parsedUrl.substring(parsedUrl.indexOf("/") + 1);
              // uploadedFileURLs.push({
              //     id: null,
              //     url: parsedUrl,
              //     file_type: files[i].type,
              //     file_name: files[i].name
              // })
              // setUploadedFileURLs(uploadedFileURLs);
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (isMultiple) {
      const files = event;
      if (files) {
        setImage(URL.createObjectURL(files));
        const { name, type } = files;
        const params = {
          key: name.includes(".") ? name.substring(0, name.indexOf(".")) : name,
          extension: type.includes("/")
            ? type.substring(type.indexOf("/") + 1)
            : type,
          belongsTo: "product",
          purpose: "product_img",
        };

        try {
          const res = await uploadFileService.getSignedUploadUrl(params);
          const url = res.data.data.signedURL;
          if (url) {
            try {
              const response = await uploadFileService.uploadFile(url, files);
              setValue("image_id", res.data.data.id);
              const reader = new FileReader();
              reader.onload = function (e) {
                // setUploadedFileURLs(uploadedFileURLs);
              };
              reader.onerror = function (e) {};
              reader.readAsDataURL(files);
              let parsedUrl = new URL(url).pathname.substring(1);
              parsedUrl = parsedUrl.substring(parsedUrl.indexOf("/") + 1);
              // uploadedFileURLs.push({
              //     id: null,
              //     url: parsedUrl,
              //     file_type: files[i].type,
              //     file_name: files[i].name
              // })
              // setUploadedFileURLs(uploadedFileURLs);
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    event.target.value = null;
  };

  useEffect(() => {
    console.log(Files);
    let arr = [];
    Files.forEach((el) => arr.push(el.id));
    Files && Files.length > 0 && setValue("details_images", arr);
  }, [Files]);
  useEffect(() => {
    if (pid) {
      let product = products.filter((el) => el.id == pid);
      if (product && product.length > 0) {
        setSelectedProducts(product[0]);
        setValue("name", product[0]?.name);
        setValue("price", product[0]?.price);
        setValue("discount_percent", product[0]?.discount_percent);
        setValue("ingredients", product[0]?.ingredients);
        setValue("description", product[0]?.description);
        setValue("status", product[0]?.status);
        setValue("size", product[0]?.size);
        setValue("gross_weight", product[0]?.gross_weight);
        setValue("category_id", product[0]?.category_id);
        setValue("usage", product[0]?.usage);
        setValue("image_id", product[0]?.image_id);
        setValue("status", product[0]?.status);
        setValue("show_product", +product[0]?.show_product);
        setValue("product_index", +product[0]?.product_index);
        setValue("details_images", +product[0]?.details_images);

        setValue("fragnance_notes", product[0]?.fragnance_notes);
        setValue("product_details", product[0]?.product_details);
        setValue("pack_1_name", product[0]?.pack_1_name);
        setValue("pack_1_is_popular", product[0]?.pack_1_is_popular);
        setValue("pack_1_is_best_value", product[0]?.pack_1_is_best_value);
        setValue("pack_1_price", product[0]?.pack_1_price);
        setValue(
          "pack_1_discount_percent",
          product[0]?.pack_1_discount_percent
        );
        setValue("pack_1_size", product[0]?.pack_1_size);
        setValue("pack_1_weight", product[0]?.pack_1_weight);
        setValue("pack_1_length", product[0]?.pack_1_length);
        setValue("pack_1_width", product[0]?.pack_1_width);
        setValue("pack_1_height", product[0]?.pack_1_height);

        setValue("pack_2_name", product[0]?.pack_2_name);
        setValue("pack_2_is_popular", product[0]?.pack_2_is_popular);
        setValue("pack_2_is_best_value", product[0]?.pack_2_is_best_value);
        setValue("pack_2_price", product[0]?.pack_2_price);
        setValue(
          "pack_2_discount_percent",
          product[0]?.pack_2_discount_percent
        );
        setValue("pack_2_size", product[0]?.pack_2_size);
        setValue("pack_2_weight", product[0]?.pack_2_weight);
        setValue("pack_2_length", product[0]?.pack_2_length);
        setValue("pack_2_width", product[0]?.pack_2_width);
        setValue("pack_2_height", product[0]?.pack_2_height);

        setValue("pack_3_name", product[0]?.pack_3_name);
        setValue("pack_3_is_popular", product[0]?.pack_3_is_popular);
        setValue("pack_3_is_best_value", product[0]?.pack_3_is_best_value);
        setValue("pack_3_price", product[0]?.pack_3_price);
        setValue(
          "pack_3_discount_percent",
          product[0]?.pack_3_discount_percent
        );
        setValue("pack_3_size", product[0]?.pack_3_size);
        setValue("pack_3_weight", product[0]?.pack_3_weight);
        setValue("pack_3_length", product[0]?.pack_3_length);
        setValue("pack_3_width", product[0]?.pack_3_width);
        setValue("pack_3_height", product[0]?.pack_3_height);

        setPackBoolVal({
          'pack_1_is_popular': product[0]?.pack_1_is_popular,
          'pack_2_is_popular': product[0]?.pack_2_is_popular,
          'pack_3_is_popular': product[0]?.pack_3_is_popular,
          'pack_1_is_best_value': product[0]?.pack_1_is_best_value,
          'pack_2_is_best_value': product[0]?.pack_2_is_best_value,
          'pack_3_is_best_value': product[0]?.pack_3_is_best_value
        })
        setShowProduct(+product[0]?.show_product);
        setProdIndex(
          product[0]?.product_index
            ? {
                key: product[0]?.product_index,
                value: product[0]?.product_index,
              }
            : null
        );
        setStatusValue(
          product[0]?.status == 0
            ? { key: 0, value: "Inctive" }
            : { key: 1, value: "Active" }
        );
        setCategoryValue(
          options.filter((el) => el.key == product[0]?.category_id)[0]
        );
        setDescVal(product[0]?.description);
        setIngredients(product[0]?.ingredients);
        setFragnance(product[0]?.fragnance_notes);
        setDetail(product[0]?.product_details);

        setUsage(product[0]?.usage);
        let tempArr = [];
        product[0].productImages.forEach((el) => {
          el.images &&
            tempArr.push({
              id: el.images.id,
              filename: el.images.original_name,
              filetype: el.images.extension,
              fileimage: el.images.path,
              datetime: el.images.updated_at.toLocaleString("en-IN"),
              filesize: el.images.size,
            });
        });
        SetFiles(tempArr);
      } else {
        // routeHistory.push("/customers");
        setValue("pack_1_is_popular", 0);
        setValue("pack_1_is_best_value", 0);
      }
    } else {
      // routeHistory.push("/customers");
      // setValue("pack_1_is_popular",0)
      // setValue("pack_1_is_best_value",0)
    }
  }, [selectedProducts]);

  const handleProduct = (data) => {
    console.log(data);
    data.status =
      data.status?.key == 0 || data.status?.key == 1
        ? data.status?.key
        : data.status;
    data.category_id = data.category_id?.key
      ? data.category_id?.key
      : data.category_id;
    setShowLoader(dispatch, true);
    if (pid) {
      productService
        .updateProduct(data, +pid)
        .then(
          (res) => {
            setSnackBar(dispatch, {
              title: "Notification",
              content: "Product details updated!",
            });
            routeHistory.push("/products");
          },
          (error) => console.error(error)
        )
        .then(() => setShowLoader(dispatch, false));
    } else {
      productService
        .addProduct(data, +pid)
        .then(
          (res) => {
            setSnackBar(dispatch, {
              title: "Notification",
              content: "Product details added!",
              color: "success",
              icon: "check",
              bgWhite: true,
            });
            routeHistory.push("/products");
          },
          (error) => console.error(error)
        )
        .then(() => setShowLoader(dispatch, false));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SimpleBlogCard
                    image={
                      image ||
                      selectedProducts?.images?.path ||
                      "https://bit.ly/3Hlw1MQ"
                    }
                    title="Product Image"
                    action={{
                      type: "upload",
                    }}
                  />
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={{ mt: -5 }}
                    fullWidth
                    onClick={onUploadClick}
                  >
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      ref={inputFile}
                      onChange={onImageChange}
                    />
                    &nbsp;Upload
                  </MDButton>
                  {errors.image_id && (
                    <span
                      style={{
                        fontWeight: 100,
                        fontSize: "small",
                        color: "red",
                      }}
                    >
                      Product Image is{" "}
                      {errors.image_id?.type === "pattern"
                        ? "invalid"
                        : "required"}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Card>
                <MDBox p={3}>
                  <MDTypography
                    display="inline"
                    variant="h5"
                    textTransform="capitalize"
                    fontWeight="bold"
                  >
                    {"Product Information"}
                  </MDTypography>
                  <Grid container spacing={2} p={2}>
                    <Grid xs={7} lg={5}>
                      <MDInput
                        type="text"
                        {...register("name", { required: true })}
                        name="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                      />
                      {errors.name && (
                        <span
                          style={{
                            fontWeight: 100,
                            fontSize: "small",
                            color: "red",
                          }}
                        >
                          Name is{" "}
                          {errors.name?.type === "pattern"
                            ? "invalid"
                            : "required"}
                        </span>
                      )}
                    </Grid>
                    <Grid xs={1} lg={1}></Grid>
                    <Grid xs={3} lg={3}>
                      <MDInput
                        type="number"
                        {...register("price")}
                        name="price"
                        label="Price"
                        variant="outlined"
                        fullWidth
                      />
                      {errors.price && (
                        <span
                          style={{
                            fontWeight: 100,
                            fontSize: "small",
                            color: "red",
                          }}
                        >
                          Price is{" "}
                          {errors.price?.type === "pattern"
                            ? "invalid"
                            : "required"}
                        </span>
                      )}
                    </Grid>
                    <Grid xs={1} lg={1}></Grid>
                    <Grid xs={2} lg={2}>
                      <MDInput
                        type="number"
                        step="0.1"
                        {...register("discount_percent", {
                          pattern: DECIMAL_REGEX,
                        })}
                        label="Discount %"
                        name="discount_percent"
                        variant="outlined"
                        fullWidth
                      />
                      {errors.discount_percent && (
                        <span
                          style={{
                            fontWeight: 100,
                            fontSize: "small",
                            color: "red",
                          }}
                        >
                          Discount is{" "}
                          {errors.discount_percent?.type === "pattern"
                            ? "invalid"
                            : "required"}
                        </span>
                      )}
                    </Grid>
                    <Grid xs={12} md={6.4}>
                      <MDTypography
                        color="text"
                        fontSize="small"
                        fontWeight="regular"
                        sx={{ mt: 3, mb: 1 }}
                      >
                        {" "}
                        Ingredients
                      </MDTypography>
                      <Ckeditor
                        activeClass="p10"
                        content={ingredients}
                        config={config}
                        events={{
                          blur: ingredientBlur,
                          afterPaste: ingredientPaste,
                          change: ingredientChange,
                        }}
                      />
                    </Grid>
                    <Grid xs={2} lg={0.6}></Grid>
                    <Grid xs={12} md={5}>
                      <MDTypography
                        color="text"
                        fontSize="small"
                        fontWeight="regular"
                        sx={{ mt: 3, mb: 1 }}
                      >
                        {" "}
                        Usage
                      </MDTypography>
                      <Ckeditor
                        activeClass="p10"
                        content={usage}
                        config={config}
                        events={{
                          blur: usageBlur,
                          afterPaste: usagePaste,
                          change: usageChange,
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6.4}>
                      <MDTypography
                        color="text"
                        fontSize="small"
                        fontWeight="regular"
                        sx={{ mt: 3, mb: 1 }}
                      >
                        {" "}
                        Fragnance Note
                      </MDTypography>
                      <Ckeditor
                        activeClass="p10"
                        content={fragnance}
                        config={config}
                        events={{
                          blur: fragnanceBlur,
                          afterPaste: fragnancePaste,
                          change: fragnanceChange,
                        }}
                      />
                    </Grid>
                    <Grid xs={2} lg={0.6}></Grid>
                    <Grid xs={12} md={5}>
                      <MDTypography
                        color="text"
                        fontSize="small"
                        fontWeight="regular"
                        sx={{ mt: 3, mb: 1 }}
                      >
                        {" "}
                        Product Detail
                      </MDTypography>
                      <Ckeditor
                        activeClass="p10"
                        content={detail}
                        config={config}
                        events={{
                          blur: detailBlur,
                          afterPaste: detailPaste,
                          change: detailChange,
                        }}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={7}>
                        <MDTypography
                          color="text"
                          fontSize="small"
                          fontWeight="regular"
                          sx={{ mt: 3, mb: 1 }}
                        >
                          {" "}
                          Description
                        </MDTypography>
                        <Ckeditor
                          activeClass="p10"
                          content={descVal}
                          config={config}
                          events={{
                            blur: descBlur,
                            afterPaste: descPaste,
                            change: descChange,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} py={8}>
                        <MDInput
                          type="text"
                          {...register("size", { required: true })}
                          name="size"
                          label="Weight(mention unit below)"
                          variant="outlined"
                          sx={{ mt: 6, mb: 1 }}
                          fullWidth
                        />
                        {/* {errors.size && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Weight is {errors.size?.type === "pattern" ? "invalid" : "required"}</span>} */}
                        <MDInput
                          type="number"
                          step="0.1"
                          {...register("gross_weight", {
                            required: true,
                            pattern: DECIMAL_REGEX,
                          })}
                          name="gross_weight"
                          label="Gross Weight(gram)"
                          variant="outlined"
                          sx={{ mt: 6, mb: 1 }}
                          fullWidth
                        />
                        {errors.gross_weight && (
                          <span
                            style={{
                              fontWeight: 100,
                              fontSize: "small",
                              color: "red",
                            }}
                          >
                            Gross Weight is{" "}
                            {errors.gross_weight?.type === "pattern"
                              ? "invalid"
                              : "required"}
                          </span>
                        )}
                        <Autocomplete
                          {...defaultProps}
                          id="product-categories"
                          value={categoryValue}
                          onChange={(event, newValue) => {
                            handleCategoryValue(newValue);
                          }}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="Catergories"
                              variant="outlined"
                              sx={{ mt: 3, mb: 1 }}
                            />
                          )}
                        />
                        <Autocomplete
                          {...defaultStatusProps}
                          id="product-status"
                          value={statusValue}
                          onChange={(event, newValue) => {
                            handleStatus(newValue);
                            name = "status";
                          }}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="Status"
                              variant="outlined"
                              sx={{ mt: 3, mb: 1 }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} sx={{ mt: -8 }}>
                        <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                          Show on Dashboard?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="product-show"
                          name="show_product"
                          value={showProduct}
                          onChange={(data) => handleShowProduct(data)}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={5} sx={{ mt: -8 }}>
                        <Autocomplete
                          {...defaultIndexProps}
                          id="product-index"
                          value={prodIndex}
                          onChange={(event, newValue) => {
                            event.stopPropagation();
                            handleProductIndex(newValue);
                          }}
                          name="product_index"
                          disabledItemsFocusable={watch("show_product") != 1}
                          disabled={watch("show_product") != 1}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="Product Index"
                              variant="outlined"
                              sx={{ mt: 3, mb: 1 }}
                              disabledItemsFocusable={
                                watch("show_product") != 1
                              }
                              disabled={watch("show_product") != 1}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} py={2}>
                        <MDTypography
                          display="inline"
                          variant="p"
                          textTransform="capitalize"
                          fontWeight="bold"
                        >
                          {"Pack 1"}
                        </MDTypography>

                        <Grid container spacing={2} py={4}>
                          <Grid item xs={6} md={2} lg={2}>
                            <MDInput
                              type="text"
                              {...register("pack_1_name", { required: true })}
                              name="pack_1_name"
                              label="Pack Name"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.name && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Name is{" "}
                                {errors.name?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={3} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_1_size", {
                                required: true,
                              })}
                              name="pack_1_size"
                              label="Pack Size"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_size && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack size is{" "}
                                {errors.pack_1_size?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_1_price", {
                                required: true,
                              })}
                              label="Pack Price"
                              name="pack_1_price"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_price && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Price is{" "}
                                {errors.pack_1_price?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_1_weight", {
                                required: true,
                              })}
                              label="Pack weight (gram)"
                              name="pack_1_weight"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_weight && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Weight is{" "}
                                {errors.pack_1_weight?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_1_discount_percent", {
                                pattern: DECIMAL_REGEX,
                              })}
                              label="Pack Discount Price"
                              name="pack_1_discount_percent"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_discount_percent && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Discount is{" "}
                                {errors.pack_1_discount_percent?.type ===
                                "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Popular Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_1_is_popular"
                              value={packBoolVal?.pack_1_is_popular || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>

                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Best Value Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_1_is_best_value"
                              value={packBoolVal?.pack_1_is_best_value || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_1_length", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Length (Cm)"
                              name="pack_1_length"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_length && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Length is{" "}
                                {errors.pack_1_length?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_1_width", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Width (Cm)"
                              name="pack_1_width"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_width && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Width is{" "}
                                {errors.pack_1_width?.type ===
                                "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_1_height", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Height (Cm)"
                              name="pack_1_height"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_height && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Height is{" "}
                                {errors.pack_1_height?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12} py={2}>
                        <MDTypography
                          display="inline"
                          variant="p"
                          textTransform="capitalize"
                          fontWeight="bold"
                        >
                          {"Pack 2"}
                        </MDTypography>

                        <Grid container spacing={2} py={4}>
                          <Grid item xs={6} md={2} lg={2}>
                            <MDInput
                              type="text"
                              {...register("pack_2_name", { required: true })}
                              name="pack_2_name"
                              label="Pack Name"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.name && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Name is{" "}
                                {errors.name?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={3} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_2_size", {
                                required: true,
                              })}
                              name="pack_2_size"
                              label="Pack Size"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_2_size && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack size is{" "}
                                {errors.pack_2_size?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_2_price", {
                                required: true,
                              })}
                              label="Pack Price"
                              name="pack_2_price"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_2_price && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Price is{" "}
                                {errors.pack_2_price?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_2_weight", {
                                required: true,
                              })}
                              label="Pack weight (gram)"
                              name="pack_2_weight"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_2_weight && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Weight is{" "}
                                {errors.pack_2_weight?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_2_discount_percent", {
                                pattern: DECIMAL_REGEX,
                              })}
                              label="Pack Discount Price"
                              name="pack_2_discount_percent"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_2_discount_percent && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Discount is{" "}
                                {errors.pack_2_discount_percent?.type ===
                                "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Popular Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_2_is_popular"
                              value={packBoolVal?.pack_2_is_popular || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>

                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Best Value Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_2_is_best_value"
                              value={packBoolVal?.pack_2_is_best_value || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_2_length", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Length (Cm)"
                              name="pack_2_length"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_length && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Length is{" "}
                                {errors.pack_2_length?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_2_width", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Width (Cm)"
                              name="pack_2_width"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_1_width && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Width is{" "}
                                {errors.pack_2_width?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_2_height", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Height (Cm)"
                              name="pack_2_height"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_2_height && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Height is{" "}
                                {errors.pack_2_height?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12} py={2}>
                        <MDTypography
                          display="inline"
                          variant="p"
                          textTransform="capitalize"
                          fontWeight="bold"
                        >
                          {"Pack 3"}
                        </MDTypography>

                        <Grid container spacing={2} py={4}>
                          <Grid item xs={6} md={2} lg={2}>
                            <MDInput
                              type="text"
                              {...register("pack_3_name", { required: true })}
                              name="pack_3_name"
                              label="Pack Name"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.name && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Name is{" "}
                                {errors.name?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={3} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_3_size", {
                                required: true,
                              })}
                              name="pack_3_size"
                              label="Pack Size"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_size && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack size is{" "}
                                {errors.pack_3_size?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_3_price", {
                                required: true,
                              })}
                              label="Pack Price"
                              name="pack_3_price"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_price && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Price is{" "}
                                {errors.pack_3_price?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              {...register("pack_3_weight", {
                                required: true,
                              })}
                              label="Pack weight (gram)"
                              name="pack_3_weight"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_weight && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Weight is{" "}
                                {errors.pack_3_weight?.type === "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_3_discount_percent", {
                                pattern: DECIMAL_REGEX,
                              })}
                              label="Pack Discount Price"
                              name="pack_3_discount_percent"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_discount_percent && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Discount is{" "}
                                {errors.pack_3_discount_percent?.type ===
                                "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Popular Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_3_is_popular"
                              value={packBoolVal?.pack_3_is_popular || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>

                          <Grid item xs={6} md={3} lg={3} sx={{ mt: 0 }}>
                            <FormLabel id="product-show" sx={{ fontSize: 12 }}>
                              Best Value Pack?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="product-show"
                              name="pack_3_is_best_value"
                              value={packBoolVal?.pack_3_is_best_value || 0}
                              onChange={(data) => handleChangeRadio(data)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_3_length", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Length (Cm)"
                              name="pack_3_length"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_length && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Length is{" "}
                                {errors.pack_3_length?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_3_width", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Width (Cm)"
                              name="pack_3_width"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_width && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Width is{" "}
                                {errors.pack_3_width?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            <MDInput
                              type="number"
                              step="0.1"
                              {...register("pack_3_height", {
                                pattern: DECIMAL_REGEX,
                                required: true 
                              })}
                              label="Pack Height (Cm)"
                              name="pack_3_height"
                              variant="outlined"
                              fullWidth
                            />
                            {errors.pack_3_height && (
                              <span
                                style={{
                                  fontWeight: 100,
                                  fontSize: "small",
                                  color: "red",
                                }}
                              >
                                Pack Height is{" "}
                                {errors.pack_3_height?.type ===
                                  "pattern"
                                  ? "invalid"
                                  : "required"}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
              <Grid item xs={12} lg={12} sx={{ mt: -2 }}>
                <Grid item xs={12} md={12}>
                  <UploadImage Files={Files} SetFiles={SetFiles} />
                </Grid>
              </Grid>
              <input
                type="hidden"
                {...register("image_id", { required: true })}
                label=""
                name="image_id"
              />
              <Grid item xs={12} lg={12} sx={{ mt: -4 }}>
                <Grid item xs={12} md={12} py={2}>
                  <Link onClick={handleSubmit((d) => handleProduct(d))}>
                    <MDButton variant="gradient" color="dark" fullWidth>
                      {pid ? "Update" : "Add"}
                    </MDButton>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ProductEdit;
