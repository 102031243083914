export const USER_AUTH_INFO = "user_auth_info";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://127.0.0.1:3333";

export const CLIENT_SECRET_KEY =
  process.env.CLIENT_SECRET_KEY || "H98zM6i/55yNJfkFsbu0HrzlFo17FtR9";

export const HMAC_SECRET_KEY =
  process.env.HMAC_SECRET_KEY || "1170ae6c5c050247c41b7e75a2b3e4fc6f5be65fdc75c3f5d0293bd1e2742202";

export const SECRET_IV = process.env.SECRET_IV || "1e3ec2a587bd5148ad1186f20317029d";
