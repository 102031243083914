import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Britacel context
import { useMaterialUIController } from "context";
import { Divider } from "@mui/material";
import data from "./data/tableData";
import DataTable from "examples/Tables/DataTable";
import { useParams } from "react-router-dom";

function ProductDetails(props) {
    const { orderItems } = props;
    const [controller] = useMaterialUIController();
    const { columns, rows } = data();
    const { orders } = controller;
    const { oid } = useParams();

    return (
        <Card id="delete-account">
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                   Order Summary
                </MDTypography>
                {/* <MDButton variant="gradient" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;add new card
        </MDButton> */}
            </MDBox>
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
            />
            <MDBox pt={2} px={6} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium" />
                <MDTypography variant="h6" fontWeight="medium">
                    Shipping Charges : ₹ {orders.filter(order => order.oid === oid)[0]?.shipping_price}
                </MDTypography>
            </MDBox>
            <MDBox pt={2} px={6} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium" />
                <MDTypography variant="h6" fontWeight="medium">
                    Total Amount : ₹ {orders.filter(order => order.oid === oid)[0]?.total}
                </MDTypography>
            </MDBox>
        </Card>
    );
}

export default ProductDetails;
