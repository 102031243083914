/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// @mui material components
import Icon from "@mui/material/Icon";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useParams } from "react-router";

export default function data() {

  const [controller, dispatch] = useMaterialUIController();
  const { orders } = controller;
  const { oid } = useParams();

  const Title = ({ name }) => (
    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
      <MDTypography variant="caption" color="text" fontWeight="bold">
        {name}
      </MDTypography>
    </MDBox>
  );
  const Amount = ({ amount }) => (
    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
      <MDTypography variant="caption" color="text" fontWeight="bold">
        ₹ {amount}
      </MDTypography>
    </MDBox>
  );

  const Description = ({ desc }) => (
    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
      <MDTypography variant="caption" color="text" fontWeight="bold">
        {desc?.length > 10 ? desc + "..." : desc}
      </MDTypography>
    </MDBox>
  );
  const generateRow = () => {
    const rows = [];
    let fetchOrder = orders.filter(order => order.oid === oid);
    {
      fetchOrder && fetchOrder[0]?.orderItems?.forEach((item) => {
        rows.push({
          image: <Icon image={item?.product?.images?.path} name={item?.product?.name} />,
          name: <Title name={item?.product?.name} />,
          // description: <Description desc={item?.product?.description} />,
          size: <Title name={item?.quantity} />,
          price: <Amount amount={item?.product?.price} />,
          discount_percent: <Title name={item?.product?.discount_percent} />,
          total: <Amount amount={item?.subtotal} />,

        });
      });
    }
    return rows;
  }
  return {
    columns: [
      { Header: "image", accessor: "image", align: "left" },
      { Header: "product name", accessor: "name", width: "30%", align: "left" },
      // { Header: "description", accessor: "description", align: "center" },
      { Header: "size", accessor: "size", align: "center" },
      { Header: "price", accessor: "price", align: "center" },
      { Header: "discount %", accessor: "discount_percent", width: "15%", align: "center" },
      { Header: "total", accessor: "total", align: "center" },
    ],

    rows: generateRow(),
  };
}
