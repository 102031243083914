import axios from "../util/axios";

const fetchAllShopItems = () => axios.get("shop");

const addShopItems = (data) => axios.post("shop/create", data);

const updateShopItems = (data, id) => axios.put("shop/" + id, data);

const removeShopItems = (id) => axios.delete("shop/" + id);

export default {
  fetchAllShopItems,
  addShopItems,
  updateShopItems,
  removeShopItems
};