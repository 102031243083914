// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Britacel example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/enquiries/data/authorsTableData";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useMaterialUIController } from "context";
import { setReplyRequest } from "context";
import enquiryService from "services/enquiry.service";
import { setShowLoader } from "context";

function Enquiries() {
  const { columns, rows } = authorsTableData();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);

  const [controller, dispatch] = useMaterialUIController();

  const { reply_request } = controller;

  const handleReply = () => {
    setOpen(false);

    setShowLoader(dispatch, true);
    let data = {
      full_name: reply_request.full_name,
      email: reply_request.email,
      reply_message: message
    }
    enquiryService
      .replyEnquiry(data)
      .then(
        (res) => {
          setReplyRequest(dispatch, null);
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  }
  useEffect(() => {
    if (reply_request) {
      setOpen(true);
    }
  }, [reply_request]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Enquiry&apos;s List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reply</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Message"
              type="reply_message"
              fullWidth
              variant="standard"
              style={{ width: 400 }}
              onChange={handleTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReply}>Send</Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Enquiries;
