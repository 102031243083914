import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setCouponList } from "context";
import CustomizedDialogs from "components/MDDialog";
import { Link } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";
import couponService from "services/coupon.service";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { coupons, isDelete } = controller;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getCouponList = () => {
    setShowLoader(dispatch, true);

    couponService
      .fetchAllCoupons()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Coupon Updated!" });
          setCouponList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getCouponList();
  }, [isDelete]);

  const Coupon = ({ code }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {code}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const ValidDate = ({ validDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {validDate.split(" ")[0]}
    </MDTypography>
  );

  const generateRow = () => {
    const rows = [];
    coupons.forEach((el) => {
      rows.push({
        code: <Coupon code={el.code} />,
        discount: <Coupon code={el.discount_value} />,
        type: <Coupon code={el.type} />,
        useFor: <Coupon code={el.can_use_for} />,
        valid_from: <ValidDate validDate={el.valid_from} />,
        valid_to: <ValidDate validDate={el.valid_until} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/coupons/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
            <ActionDialog
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              buttontext="delete"
              buttonname="Delete"
              body={"Are you sure you want to delete this coupon ?"}
              open={open}
              setOpen={() => setOpenId(el.id)}

              onClick={() => setOpenId(el.id)}
              cid={selectedId}
              title="Delete Coupon&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </MDBox>
        ),
      });
    });
    return rows;
  };
  return {
    columns: [
      { Header: "code", accessor: "code", width: "30%", align: "left" },
      { Header: "discount", accessor: "discount", align: "left" },
      { Header: "Type", accessor: "type", align: "center" },
      { Header: "Use For", accessor: "useFor", align: "center" },
      { Header: "Valid From", accessor: "valid_from", align: "left" },
      { Header: "Valid Untill", accessor: "valid_to", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
