import axios from "../util/axios";

const fetchAllOptions = () => axios.get("references");

const addReference = (data) => axios.post("references/create", data);


export default {
  fetchAllOptions,
  addReference,
};
