import { useEffect } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setOrderList } from "context";
import orderService from "services/order.service";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { orders } = controller;

  const getOrderList = () => {
    setShowLoader(dispatch, true);

    orderService
      .fetchAllOrders()
      .then(
        (res) => {
          setOrderList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const Order = ({ oid, razorpayOrderId, razorpayPaymentId }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium" color="text" >
          {oid}
        </MDTypography>
        <MDTypography variant="caption" color="text" >
          {razorpayOrderId} - {razorpayPaymentId}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Customer = ({ name, mobile }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium" color="text">
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="light" >
          {mobile}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Items = ({ itemArray }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <MDTypography display="block" variant="button" color="text" fontWeight="light" style={{ marginLeft: "-1em" }}>
        {itemArray[0]?.product?.name} {itemArray.length > 1 ? "+ " + itemArray.length + " more" : ""}
      </MDTypography>
    </MDBox>
  );
  const Status = ({ status }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {status === "finished" && <Icon fontSize="small" color="success" >task_alt</Icon>}
      {status === "cancelled" && <Icon fontSize="small" color="error" >close</Icon>}
      {status === "shipped" && <Icon fontSize="small" color="warning" >local_shipping</Icon>}
      {status === "paid" && <Icon fontSize="small" color="success" >task_alt</Icon>}
      {status === "pending" && <Icon fontSize="small" color="info" >query_builder</Icon>}
      {status === "refunded" && <Icon fontSize="small" color="info" >replay</Icon>}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="bold" color="dark" style={{ textTransform: "capitalize", marginLeft: "-1em" }}>
          {status == 'shipped' ? 'In Trasit' : status == 'finished' ? 'Delivered' : status}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate}
    </MDTypography>
  );

  const Coupon = ({ couponcode }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {couponcode}
    </MDTypography>
  );
  const generateRow = () => {
    const rows = [];
    orders.forEach((el) => {
      rows.push({
        order: (
          <Order
            oid={el.oid}
            razorpayOrderId={el.Razorpayorder_id}
            razorpayPaymentId={el.Razorpaypayment_id}
          />
        ),
        price: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹ {el.total}
          </MDTypography>
        ),
        status: <Status status={el.status} />,
        products: <Items itemArray={el.orderItems} />,
        customer: (
          <Customer
            name={el.addresses.first_name}
            mobile={el.addresses.mobile}
          />
        ),
        orderat: <Created createDate={el.updated_at} />,
        coupon: <Coupon couponcode={el.couponOrders && el.couponOrders[0]?.coupons?.code} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/orders/order-details/${el.oid}`}>
              <MDBadge
                badgeContent="view"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
          </MDBox>
        ),
      });
    });
    return rows;
  };

  return {
    columns: [
      { Header: "order", accessor: "order", width: "30%", align: "left" },
      { Header: "price", accessor: "price", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "products", accessor: "products", align: "center" },
      { Header: "customer", accessor: "customer", align: "center" },
      { Header: "coupon", accessor: "coupon", align: "center" },
      { Header: "updated at", accessor: "orderat", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
