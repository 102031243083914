import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./Constants";
import MessageParser from "./MessageParser";
import encryptRequest from "./middleware";

const instance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
});

const noAuthRequestUrl = "/sign-in";

instance.interceptors.request.use(
  (request) => encryptRequest(request),
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const msg = MessageParser.parseError(error?.response?.data?.message) || error?.message;
    const url = error?.url || MessageParser.parseError(error?.response?.data?.url);

    if (msg) toast.error(msg);
    if (url) window.open(url, "_blank");
    if (error.response?.status === 401 && window.location.href.indexOf(noAuthRequestUrl) < 0) {
      localStorage.clear();
      window.location.href = noAuthRequestUrl;
    }
    return Promise.reject(error);
  }
);

export default instance;
