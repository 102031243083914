import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    color: "black",
    borderTop: "0.0625rem solid #dee2e6 !important",
    borderBottom: "0.0625rem solid #dee2e6 !important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    color: "black",
    borderTop: "0.0625rem solid #dee2e6 !important",
    borderBottom: "0.0625rem solid #dee2e6 !important",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const { buttonname, buttontext, body, title } = props;
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MDButton onClick={handleClickOpen} color={darkMode ? "dark" : "light"}>
        {buttonname}
      </MDButton>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle color="#000" onClose={handleClose}>
          <MDTypography color="#000"> {title} </MDTypography>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          style={{
            borderTop: "0.0625rem solid #dee2e6 !important",
            borderBottom: "0.0625rem solid #dee2e6 !important",
          }}
        >
          <MDTypography color="#000"> {body} </MDTypography>
        </DialogContent>
        <DialogActions>
          <MDButton autoFocus onClick={handleClose}>
            {buttontext}
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
CustomizedDialogs.propTypes = {
  buttonname: PropTypes.string.isRequired,
  buttontext: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
