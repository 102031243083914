import { USER_AUTH_INFO } from "./Constants";

const noAuthRequestUrls = ["authorise"];

const encryptRequest = (request) => {
  if (!noAuthRequestUrls.includes(request.url) && !request.url.includes('https://s3.ap-south-1.amazonaws.com/')) {
    const authInfo = JSON.parse(localStorage.getItem(USER_AUTH_INFO));
    request.headers.Authorization = `Bearer ${authInfo?.token}`;
  }
  request.headers["Content-Type"] = `application/json`;
  request.headers["Access-Control-Allow-Origin"] = "*";
  return request;
};
export default encryptRequest;
