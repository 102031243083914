import axios from "../util/axios";
import axiosMain from 'axios';

const getSignedUploadUrl = (params) => {
    // const authInfo = JSON.parse(localStorage.getItem(USER_AUTH_INFO));
    return axios.post(`uploads/create`, { params });
}

const getSignedDownloadUrl = (params) => {
    return axios.get(`download`, { params });
}

const uploadFile = async (url, file) => {
    return axiosMain.put(url, file);
}

export default {
    getSignedUploadUrl,
    getSignedDownloadUrl,
    uploadFile
}
