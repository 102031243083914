import { Autocomplete, Card, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setShowLoader } from "context";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import profileService from "services/profile.service";
import authService from "services/auth.service";
import { setCustomerList } from "context";
import { setProfileUrl } from "context";
import uploadFileService from "services/upload-file.service";

const UserProfile = () => {
    const inputFile = useRef(null);
    const [image, setImage] = useState(null)

    const [controller, dispatch] = useMaterialUIController();

    const { customers } = controller;
    const uid = 1;
    const [selectedUser, setSelectedUser] = useState(null);
    const [isBan, setIsBan] = useState(false);
    const routeHistory = useHistory();
    const MOBILE_NUMBER_REGEX = /^[0-9]{10}$/;
    const [imageURL, setImageURL] = useState(false);

    const { control, register, watch, formState: { errors }, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: selectedUser?.profile
    });
    const onUploadClick = () => {
        inputFile.current.click();
    }

    const getUserList = () => {
        setShowLoader(dispatch, true);

        authService
            .fetchAllUsers()
            .then(
                (res) => {
                    // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Customer list is loaded." });
                    setCustomerList(dispatch, res.data);
                    setProfileUrl(dispatch, res.data[0].profile?.images?.path);
                    // setSelectedUser(res.data[0]);
                    setValue("first_name", res.data[0]?.profile?.first_name);
                    setValue("last_name", res.data[0]?.profile?.last_name);
                    setValue("mobile", res.data[0]?.profile?.mobile);
                    setValue("country", res.data[0]?.profile?.country);
                    setValue("state", res.data[0]?.profile?.state);
                    setValue("image_id", res.data[0]?.profile?.image_id);
                    setValue("city", res.data[0]?.profile?.city);
                    setIsBan(res.data[0].banned == 0 ? true : false);
                    setImageURL(res.data[0]?.profile?.images?.path);
                    //  .push("/customers");
                },
                (error) => console.error(error)
            )
            .then(() => setShowLoader(dispatch, false));
    };


    useEffect(() => {
        console.log(uid, customers)
        if (uid > 1 && customers.length > 0) {
            let user = customers.filter(el => el.id == uid);
            if (user && user.length > 0) {
                setSelectedUser(user[0]);
                setValue("first_name", user[0]?.profile?.first_name);
                setValue("last_name", user[0]?.profile?.last_name);
                setValue("mobile", user[0]?.profile?.mobile);
                setValue("country", user[0]?.profile?.country);
                setValue("state", user[0]?.profile?.state);
                setValue("image_id", user[0]?.profile?.image_id);
                setValue("city", user[0]?.profile?.city);
                setIsBan(user[0].banned == 0 ? true : false);
            } else {
                // routeHistory.push("/customers");
            }
        } else {
            // routeHistory.push("/customers");
            getUserList();
        }
        return () => {
            setSelectedUser(null)
        };
    }, [selectedUser]);
    const onImageChange = async(event) => {
        const files = event.target.files;
        if (files && files[0]) {
            setImage(URL.createObjectURL(files[0]));
            const { name, type, size } = files[0];
            const params = {
                key: name.includes('.') ? name.substring(0, name.indexOf('.')) : name,
                extension: type.includes('/') ? type.substring(type.indexOf('/') + 1) : type,
                belongsTo: 'customer',
                purpose: 'profile_pic',
                size: size,
            };

            try {
                const res = await uploadFileService.getSignedUploadUrl(params);
                const url = res.data.data.signedURL;
                if (url) {
                    try {
                        const response = await uploadFileService.uploadFile(url, files[0]);
                        setValue("image_id", res.data.data.id);
                        const reader = new FileReader();
                        reader.onload = function (e) {
                            // setUploadedFileURLs(uploadedFileURLs);
                        };
                        reader.onerror = function (e) {

                        }
                        reader.readAsDataURL(files[0]);
                        let parsedUrl = new URL(url).pathname.substring(1);
                        parsedUrl = parsedUrl.substring(parsedUrl.indexOf('/') + 1);
                        // uploadedFileURLs.push({
                        //     id: null,
                        //     url: parsedUrl,
                        //     file_type: files[i].type,
                        //     file_name: files[i].name
                        // })
                        // setUploadedFileURLs(uploadedFileURLs);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        event.target.value = null;
    }
    const handleUpdateProfile = (data) => {
        setShowLoader(dispatch, true);
        profileService
            .updateProfile(data, +uid)
            .then(
                (res) => {
                    routeHistory.push("/dashboard");
                },
                (error) => console.error(error)
            )
            .then(() => setShowLoader(dispatch, false));
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <SimpleBlogCard
                                        image={image || imageURL || "https://bit.ly/3Hlw1MQ"}
                                        title="Profile Picture"
                                        action={{
                                            type: "upload",
                                        }}

                                    />
                                    <MDButton variant="gradient" color="dark" sx={{ mt: -5 }} fullWidth onClick={onUploadClick}>
                                        <input hidden accept="image/*" multiple type="file" ref={inputFile} onChange={onImageChange} />
                                        &nbsp;Upload
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Card>
                                <MDBox p={3}>
                                    <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">
                                        {"Profile Information"}
                                    </MDTypography>
                                    <Grid container sx={2} p={2}>
                                        <Grid xs={12} lg={5.9} py={2}>
                                            <MDInput type="text" {...register("first_name", { required: true })} label="First Name" variant="outlined" name="first_name" fullWidth />
                                            {errors.first_name && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>First Name is {errors.first_name?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid xs={12} lg={0.2}>
                                        </Grid>
                                        <Grid item xs={12} md={5.9} py={2}>
                                            <MDInput type="text" {...register("last_name", { required: true })} label="Last Name" variant="outlined" name="last_name" fullWidth />
                                            {errors.last_name && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Last Name is {errors.last_name?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid xs={12} lg={12} py={2}>
                                            <MDInput type="number" {...register("mobile", { required: true, pattern: MOBILE_NUMBER_REGEX })} label="Mobile Number" name="mobile" variant="outlined" fullWidth />
                                            {errors.mobile && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Mobile is {errors.mobile?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid item xs={12} md={5.9} py={2}>
                                            <MDInput type="text" {...register("city", { required: true })} label="City" name="city" variant="outlined" fullWidth />
                                            {errors.city && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>City is {errors.city?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid item xs={12} md={0.2}>
                                        </Grid>
                                        <Grid item xs={12} md={5.9} py={2}>
                                            <MDInput type="text" {...register("state", { required: true })} label="State" name="state" variant="outlined" fullWidth />
                                            {errors.state && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>State is {errors.state?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid item xs={12} md={5.9} py={4}>
                                            <MDInput type="text" {...register("country", { required: true })} label="Country" name="country" variant="outlined" fullWidth />
                                            {errors.country && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>country is {errors.country?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid item xs={12} md={0.2}>
                                        </Grid>
                                        <Grid item xs={12} md={5.9} py={4}>
                                            <Link onClick={handleSubmit((d) => handleUpdateProfile(d))} >
                                                <MDButton variant="gradient" color="dark" fullWidth>
                                                    &nbsp;update
                                                </MDButton>
                                            </Link>
                                        </Grid>
                                        <input type="hidden" {...register("image_id")} label="" name="image_id" />
                                    </Grid>
                                </MDBox>
                            </Card >
                        </Grid >
                    </Grid >
                </MDBox >
            </MDBox >
            <Footer />
        </DashboardLayout >
    );
};

export default UserProfile;