// @mui material components
import Grid from "@mui/material/Grid";

// Britacel components
import MDBox from "components/MDBox";

// Britacel example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import authService from "services/auth.service";
import { useEffect, useState } from "react";
import { useMaterialUIController, setProfileUrl, setShowLoader } from "context";
import dashboardService from "services/dashboard.service";
import moment from "moment";

function Dashboard() {
  // const { tasks } = reportsLineChartData;
  const [controller, dispatch] = useMaterialUIController();
  const [dashboardData, setDashboardData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [barData, setBarData] = useState(null);



  // tasks{
  //   labels: ["Sep", "Oct", "Nov", "Dec", "Jan", "Feb"],
  //   datasets: { label: "Orders", data: [50, 40, 300, 220, 500, 250] },
  // },


  useEffect(() => {
    handleProfileURL();
    handleDashboardData();
  }, []);

  useEffect(() => {
    console.log(tasks, barData)
  }, [tasks, barData]);

  const updateMonthOrder = (data) => {
    console.log(data?.order_by_month[0]);
    let label = [];
    let dataS = [];
    data?.order_by_month[0].forEach(el => {
      label.push(el.MONTH.substring(0, 3));
      dataS.push(el.total);
    });
    setTasks({
      labels: label,
      datasets: { label: "Orders", data: dataS },
    })
  }

  const updateDayOrder = (data) => {
    console.log(data?.order_by_day[0]);
    let label = [];
    let dataS = [];

    data?.order_by_day[0].forEach(el => {
      label.push(el.DAY.substring(0, 2));
      dataS.push(el.total);
    });
    setBarData({
      labels: label,
      datasets: { label: "Orders", data: dataS },
    })
  }

  const handleProfileURL = () => {
    setShowLoader(dispatch, true);

    authService
      .getLoggedInUser()
      .then(
        (res) => {
          console.log(res);
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Customer list is loaded." });
          setProfileUrl(dispatch, res.data.profile?.images?.path);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  }
  const handleDashboardData = () => {
    setShowLoader(dispatch, true);

    dashboardService
      .fetchDashboardData()
      .then(
        (res) => {
          console.log(res);
          setDashboardData(res.data?.data);
          updateMonthOrder(res.data?.data);
          updateDayOrder(res.data?.data);

        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Orders"
                count={dashboardData?.totalOrders}
                percentage={{
                  color: dashboardData?.percentChangePerWeekOrder > 0 ? "success" : "error",
                  amount: dashboardData?.percentChangePerWeekOrder > 0 ? "+" + dashboardData?.percentChangePerWeekOrder + "%" : dashboardData?.percentChangePerWeekOrder + "%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="New Order"
                count={dashboardData?.currentMonthOrders}
                percentage={{
                  color: dashboardData?.percentChangeMonthOrders > 0 ? "success" : "error",
                  amount: dashboardData?.percentChangeMonthOrders > 0 ? "+" + dashboardData?.percentChangeMonthOrders + "%" : dashboardData?.percentChangeMonthOrders + "%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="5k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="New Users"
                count={dashboardData?.currMonthUsers}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="month orders"
                  description="Current Month"
                  date={moment().format('MMMM')}
                  chart={barData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <OrdersOverview overview={dashboardData?.ordersOverview} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Order Placed"
                  description="Last 6 Month's Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
