import axios from "util/axios";
import { USER_AUTH_INFO } from "util/Constants";

const login = (data) => {
  localStorage.clear();
  return axios.post("auth/authorise", data);
};
const logout = () => {
  localStorage.clear();
  window.location.href = "/sign-in";
};

const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_AUTH_INFO));
  } catch (error) {
    localStorage.clear();
    window.location.href = "/sign-in";
  }
  return "";
};

const isLoggedIn = () => !!localStorage.getItem(USER_AUTH_INFO);

const changePassword = (data) => axios.post("auth/update/password", data);

const fetchAllUsers = () => axios.get("auth/users");

const toggleUserBan = (id) => axios.get("auth/toggle/user/ban/" + id);

const deleteUser = (id) => axios.get("auth/remove/user/" + id);

const getLoggedInUser = () => axios.get("auth/me");

export default {
  login,
  logout,
  getCurrentUser,
  isLoggedIn,
  changePassword,
  fetchAllUsers,
  toggleUserBan,
  deleteUser,
  getLoggedInUser,
};
