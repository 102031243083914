import axios from "../util/axios";

const fetchAllEnquiries = () => axios.get("enquiries");

const replyEnquiry = (data) => axios.put("enquiries/reply", data);

export default {
  fetchAllEnquiries,
  replyEnquiry,
};
