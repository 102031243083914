/** 
  All of the routes for theBritacel are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Britacel layouts
import Dashboard from "layouts/dashboard";
import Categories from "layouts/categories";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import SignIn from "layouts/authentication/sign-in";
import Products from "layouts/products";
import Orders from "layouts/orders";
import Customers from "layouts/customers";
import OrdersDetails from "layouts/orders/details";

// @mui icons
import Icon from "@mui/material/Icon";
import requireLogin from "./guards/requireLogin";
import CategoriesEdit from "layouts/categories/components/categories";
import ProductEdit from "layouts/products/components/product";
import CustomerEdit from "layouts/customers/update/customer";
import UserProfile from "layouts/profile/UserProfile";
import Coupons from "layouts/coupons";
import CouponEdit from "layouts/coupons/components/coupons";
import Enquiries from "layouts/enquiries";
import Subscribes from "layouts/subscribes";
import CRMS from "layouts/crm";
import CRMEdit from "layouts/crm/components/crms";
import Journals from "layouts/journals";
import JournalEdit from "layouts/journals/components/journals";
import Shop from "layouts/shop";
import ShopEdit from "layouts/shop/components/shop";

export const GLOBAL_GUARDS = [requireLogin];
export const excludeProfileLoadForRoutes = ["/sign-in", "/"];
export const AUTH_ONLY = "AUTH_ONLY";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Customer Add",
    key: "customer-add",
    icon: <Icon fontSize="small">person_search</Icon>,
    route: "/customers/add",
    component: <CustomerEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Customer Edit",
    key: "customer-edit",
    icon: <Icon fontSize="small">person_search</Icon>,
    route: "/customers/edit/:uid",
    component: <CustomerEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">person_search</Icon>,
    route: "/customers",
    component: <Customers />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Coupons Add",
    key: "coupons-add",
    route: "/coupons/add",
    component: <CouponEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Coupons Edit",
    key: "coupons-edit",
    route: "/coupons/edit/:cid",
    component: <CouponEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Coupons",
    key: "coupons",
    icon: <Icon fontSize="small">new_label</Icon>,
    route: "/coupons",
    component: <Coupons />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Categories Add",
    key: "categories-add",
    route: "/categories/add",
    component: <CategoriesEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Categories Edit",
    key: "categories-edit",
    route: "/categories/edit/:catid",
    component: <CategoriesEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <Categories />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Product Add",
    key: "product-add",
    route: "/products/add",
    component: <ProductEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Product Edit",
    key: "product-edit",
    route: "/products/edit/:pid",
    component: <ProductEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">sell</Icon>,
    route: "/products",
    component: <Products />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Orders Details",
    key: "orders/order%20details",
    route: "/orders/order-details/:oid",
    component: <Billing />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">shopping_basket</Icon>,
    route: "/orders",
    component: <Orders />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Billing",
    key: "billing",
    route: "/billing",
    component: <Billing />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Notifications",
    key: "notifications",
    route: "/notifications",
    component: <Notifications />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Enquiries",
    key: "enquiries",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/enquiries",
    component: <Enquiries />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Profile",
    key: "profile",
    route: "/profile",
    component: <UserProfile />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Subscribes",
    key: "subscribes",
    icon: <Icon fontSize="small">notifications_active</Icon>,
    route: "/subscribes",
    component: <Subscribes />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "divider",
    name: "divider",
    key: "divider",
    route: "/divider",
    component: <Subscribes />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "CRM Add",
    key: "contact-add",
    route: "/crm/add",
    component: <CRMEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "CRM Edit",
    key: "contact-edit",
    route: "/crm/edit/:crmid",
    component: <CRMEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "CRM",
    key: "crm",
    icon: <Icon fontSize="small">contact_mail</Icon>,
    route: "/crm",
    component: <CRMS />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Journal Add",
    key: "journal-add",
    route: "/journals/add",
    component: <JournalEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Journal Edit",
    key: "journal-edit",
    route: "/journals/edit/:jid",
    component: <JournalEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Journals",
    key: "journals",
    icon: <Icon fontSize="small">menu_book</Icon>,
    route: "/journals",
    component: <Journals />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Shop Add",
    key: "shop-add",
    route: "/shop/add",
    component: <ShopEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "title",
    name: "Shop Edit",
    key: "shop-edit",
    route: "/shop/edit/:sid",
    component: <ShopEdit />,
    meta: { [AUTH_ONLY]: true },
  },
  {
    type: "collapse",
    name: "Shop",
    key: "shop",
    icon: <Icon fontSize="small">shopping_basket</Icon>,
    route: "/shop",
    component: <Shop />,
    meta: { [AUTH_ONLY]: true },
  },
];

export default routes;
