import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
// @mui material components
import Grid from "@mui/material/Grid";

// Britacel components
import MDBox from "components/MDBox";

// Britacel examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import { useMaterialUIController } from "context";
import { setShowLoader } from "context";
import TrackOrder from "./data/trackOrder";
import ShipmentTrackOrder from "./data/shipmentTrackOrder";

import ProductDetails from "./components/ProductDetails";

function Billing() {

  const [controller, dispatch] = useMaterialUIController();
  const routeHistory = useHistory();

  const [order, setOrder] = useState([]);
  const { orders } = controller;
  const { oid } = useParams();

  const getOrderDetails = async () => {
    let fetchOrder = orders.filter(order => order.oid === oid);
    await setOrder(fetchOrder[0]);
    if (fetchOrder && fetchOrder.length === 0) {
      routeHistory.push("/orders");
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProductDetails orderItems={order?.orderItems} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices createdt={moment(order?.created_at).format("LLL")} oid={order?.oid} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation
                name={order?.addresses?.first_name}
                mobile={order?.addresses?.mobile}
                email={order?.addresses?.email}
                addr_line1={order?.addresses?.addr_line1}
                addr_line2={order?.addresses?.addr_line2}
                city={order?.addresses?.city}
                state={order?.addresses?.state}
                pincode={order?.addresses?.pincode}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TrackOrder createdt={order?.created_at} updatedt={order?.updated_at} status={order?.status} oid={oid} dispatch={dispatch}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <ShipmentTrackOrder createdt={order?.created_at} updatedt={order?.updated_at} status={order?.shipment_status} oid={oid} dispatch={dispatch} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
