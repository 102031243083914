import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setCustomerList, setSnackBar } from "context";
import authService from "services/auth.service";
import { Link } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";
import CustomizedDialogs from "components/MDDialog";
import { setProfileUrl } from "context";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { customers, isDelete } = controller;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getUserList = () => {
    setShowLoader(dispatch, true);

    authService
      .fetchAllUsers()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Customer list is loaded." });
          setCustomerList(dispatch, res.data);
          setProfileUrl(dispatch, res.data[0].profile?.images?.path);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getUserList();  
  }, [isDelete]);

  const Customer = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Role = ({ title, state, country }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">
        {state}, {country}
      </MDTypography>
    </MDBox>
  );

  const Status = ({ banned }) => (
    <MDBox ml={-1}>
      <MDTypography color={`${banned === 1 ? "error" : "success"}`} variant="gradient" size="sm">
        {`${banned === 1 ? "Banned" : "Active"}`}
      </MDTypography>
    </MDBox>
  );

  const Verified = ({ verified }) => (
    <MDBox ml={-1}>
      <MDTypography
        color={`${verified === 1 ? "success" : "warning"}`}
        variant="gradient"
        size="sm"
      >
        {`${verified === 1 ? "Verified" : "Not Verified"}`}
      </MDTypography>
    </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const handleOpen = () => {
    !open ? setOpen(true) : '';
  };

  const generateRow = () => {
    const rows = [];
    customers.length > 0 && customers?.forEach((el) => {
      rows.push({
        customer: <Customer image={el.profile?.images?.path} name={el.username} email={el.email} />,
        role: <Role title={el.role} state={el?.profile?.state} country={el?.profile?.country} />,
        status: <Status banned={el.banned} />,
        created: <Created createDate={el.created_at} />,
        verification: <Verified verified={el.verified} />,
        action: (<>
          <MDBox ml={-1}>
            <Link to={`/customers/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>

            <ActionDialog
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              buttontext="delete"
              buttonname="Delete"
              body={"Are you sure you want to delete the customer ?"}
              open={open}
              setOpen={() => setOpenId(el.id)}

              onClick={() => setOpenId(el.id)}
              uid={selectedId}
              title="Delete Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </MDBox>

          {/* <MDBox ml={-1} onClick={() => handleOpen()}>
            <MDBadge
              badgeContent="delete"
              color="error"
              variant="gradient"
              size="sm"
              style={{
                cursor: "pointer",
              }}
            />
          </MDBox> */}
        </>
        ),
      });
    });
    return rows;
  };


  return {
    columns: [
      { Header: "customer", accessor: "customer", width: "35%", align: "left" },
      { Header: "role", accessor: "role", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "create date", accessor: "created", align: "left" },
      { Header: "verification", accessor: "verification", align: "center" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
