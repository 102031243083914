import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useHistory, useParams } from "react-router";
import productService from "services/product.service";
import categoryService from "services/category.service";
import authService from "services/auth.service";
import couponService from "services/coupon.service";
import { setShowLoader, setSnackBar, setIsDelete } from "context";
import shopService from "services/shop.service";
import journalService from "services/journal.service";
import crmService from "services/crm.service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        color: "black",
        borderTop: "0.0625rem solid #dee2e6 !important",
        borderBottom: "0.0625rem solid #dee2e6 !important",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
        color: "black",
        borderTop: "0.0625rem solid #dee2e6 !important",
        borderBottom: "0.0625rem solid #dee2e6 !important",
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
    const { buttonname, buttontext, body, title, uid, catid, pid, cid, sid, jid, crmid, open, setOpen } = props;
    // const [open, setOpen] = React.useState(false);
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode } = controller;

    const routeHistory = useHistory();

    const handleClickOpen = () => {
        setOpen(true);
        setIsDelete(dispatch, false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log( uid, catid, pid, cid, sid, jid, crmid, open)

    }, [open]);
    const handleDelete = () => {
        setShowLoader(dispatch, true);
        if (uid) {
            authService
                .deleteUser(uid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Customer Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/customers");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (catid) {
            categoryService
                .removeCategory(catid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Category Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/categories");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (pid) {
            productService
                .removeProduct(pid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Product Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/products");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (cid) {
            couponService
                .removeCoupon(cid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Coupon Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/coupons");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (crmid) {
            crmService
                .removeCRM(crmid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Data Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/crm");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (jid) {
            journalService
                .removeJournal(jid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Journal Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/journals");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else if (sid) {
            shopService
                .removeShopItems(sid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Shop Deleted!" });
                        setIsDelete(dispatch, true);
                        routeHistory.push("/shop");
                    },
                    (error) => console.error(error)
                )
                .then(() => setShowLoader(dispatch, false));
        } else {
            setShowLoader(dispatch, false);
        }
        setOpen(false);
    };

    return (
        <div>
            <MDButton onClick={handleClickOpen}>
                <MDBadge
                    badgeContent="delete"
                    color="error"
                    variant="gradient"
                    size="sm"
                    style={{
                        cursor: "pointer",
                    }}
                />
            </MDButton>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle color="#000" onClose={handleClose}>
                    <MDTypography color="#000"> {title} </MDTypography>
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    style={{
                        borderTop: "0.0625rem solid #dee2e6 !important",
                        borderBottom: "0.0625rem solid #dee2e6 !important",
                    }}
                >
                    <MDTypography component="div" variant="button" color="#000"> {body} </MDTypography>
                </DialogContent>
                <DialogActions>
                    <MDButton autoFocus onClick={() => handleDelete()} color="error">
                        {buttontext}
                    </MDButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
CustomizedDialogs.propTypes = {
    buttonname: PropTypes.string.isRequired,
    buttontext: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
