import axios from "../util/axios";

const fetchAllOrders = () => axios.get("orders");

const fetchOrderById = (id) => axios.get(`orders/${id}`);

const downloadPDFInvoice = (data) => axios.post(`download`, data);

const updateStatus = (data) => axios.put(`orders`, data);

const downloadCSV = (limit) => axios.get(`csv-download/${limit}`);

export default {
  fetchAllOrders,
  fetchOrderById,
  downloadPDFInvoice,
  updateStatus,
  downloadCSV
};
