export default class MessageParser {
  static message(messages) {
    const strMessage = JSON.stringify(messages);
    const regex = /]|[[]/g;
    const regexDoubleQuotes = /["]/g;
    const message = strMessage.replace(regex, "");
    return message.replace(regexDoubleQuotes, "");
  }

  static handleArray(error) {
    if (error && error.length > 0) {
      return this.parseError(error[0]);
    }
    return null;
  }

  static handleObject(error) {
    if (error && Object.keys(error).length > 0) {
      if (error.response) {
        this.parseError(error.response);
      } else if (error.message) {
        this.parseError(error.message);
      } else if (error.error) {
        this.parseError(error.error);
      } else if (error.errors) {
        this.parseError(error.errors);
      } else {
        const firstKey = Object.keys(error)[0];
        return `${this.titleCase(firstKey)} ${this.parseError(error[firstKey])}`;
      }
    }
    return null;
  }

  static titleCase(str) {
    return str
      .split("")
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  static parseError(error) {
    if (Array.isArray(error)) {
      this.handleArray(error);
    } else {
      // eslint-disable-next-line default-case
      switch (typeof error) {
        case "string":
          return error;
        case "object":
          return this.handleObject(error);
      }
    }
    return null;
  }

  /**
   *  To check if error is due to bad gateway or timeout with status code 502 and 504
   * @param {} error : passed through server response
   */
  static isBadGatewayError(error) {
    if (error && error.response) {
      return error.response.status >= 500 && error.response.status < 600;
    }
    return false;
  }
}
