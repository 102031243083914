import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setJournalList, setSnackBar } from "context";
import categoryService from "services/category.service";
import CustomizedDialogs from "components/MDDialog";
import { Link } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";
import journalService from "services/journal.service";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { journals, isDelete } = controller;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getJournalList = () => {
    setShowLoader(dispatch, true);

    journalService
      .fetchAllJournals()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setJournalList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getJournalList();
  }, [isDelete]);

  const CRM = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Description = ({ description }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <CustomizedDialogs
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        buttonname="description"
        buttontext="close"
        body={description}
        title="Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    </MDBox>
  );

  const Image = ({ image, name }) => (
    <MDAvatar src={image} name={name} size="sm" />
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const generateRow = () => {
    const rows = [];
    console.log(journals)
    journals?.forEach((el) => {
      rows.push({
        title: <CRM name={el.title} />,
        description: <Description description={el.description} />,
        source: <CRM name={el.source_type} />,
        image: el.source_type != "video" ? <Image image={el.images?.path} name={el.images?.original_name} /> : <a href={el.images?.path} className="file-action-btn" download={el.images?.path}>Link</a>,
        created: <Created createDate={el.created_at} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/journals/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
            <ActionDialog
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              buttontext="delete"
              buttonname="Delete"
              body={"Are you sure you want to delete this journal ?"}
              open={open}
              setOpen={() => setOpenId(el.id)}

              onClick={() => setOpenId(el.id)}
              jid={selectedId}
              title="Delete Journal&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </MDBox>
        ),
      });
    });
    return rows;
  };

  return {
    columns: [
      { Header: "title", accessor: "title", align: "left" },
      { Header: "description", accessor: "description", align: "left" },
      { Header: "source", accessor: "source", align: "left" },
      { Header: "image/video", accessor: "image", align: "center" },
      { Header: "create date", accessor: "created", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
