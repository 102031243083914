import authService from "services/auth.service";

export const AUTH_ONLY = "AUTH_ONLY";

const requireLogin = (to, from, next) => {
  // check if user is authorized
  if (to.meta[AUTH_ONLY] && !authService.isLoggedIn()) {
    next.redirect("/sign-in");
  }
  next();
};

export default requireLogin;
