export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MOBILE_NUMBER_REGEX = /^[0-9]{10}$/;

export const ONE_CAPITAL_AND_SMALL_LETTER_REGEX = /(?=.*[a-z])(?=.*[A-Z])/;

export const ONE_DIGIT_AND_SPECIAL_CHARACTER = /(?=.*\d)(?=.*[-+_!@#$%^&*.,?])/;

export const PASSWORD_REGEX = /((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]))/;

export const SSN_REGEX = /(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}/;

export const ALLOW_LETTERS_ONLY = /^[a-zA-Z]+$/;

export const RATING_SELECTION = /^[1-5]\d*$/;

export const DEA_REGEX = /^[a-zA-Z]{2}\d{7}$/;
