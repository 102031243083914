import React, { useState } from "react";

import './upload.css';
import uploadFileService from "services/upload-file.service";

const UploadShopItem = (props) => {
    const [selectedfile, SetSelectedFile] = useState([]);
    const { Files, SetFiles } = props;


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {

                onImageChange(file)
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }


    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        } else {
            // alert('No');
        }

    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            console.log(selectedfile)
            for (let index = 0; index < selectedfile.length; index++) {
                SetFiles((preValue) => {
                    return [
                        ...preValue,
                        selectedfile[index]
                    ]
                })

            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }


    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
        } else {
            // alert('No');
        }
    }

    const onImageChange = async (fileObj) => {
        const files = fileObj;

        if (files) {
            // setImage(URL.createObjectURL(files));
            const { name, type } = files;
            const params = {
                key: name.includes('.') ? name.substring(0, name.indexOf('.')) : name,
                extension: type.includes('/') ? type.substring(type.indexOf('/') + 1) : type,
                belongsTo: 'journal',
                purpose: 'journal_img'
            };

            try {
                const res = await uploadFileService.getSignedUploadUrl(params);
                const url = res.data.data.signedURL;
                if (url) {
                    try {
                        const response = await uploadFileService.uploadFile(url, files);
                        // setValue("image_id", res.data.data.id);

                        const reader = new FileReader();
                        reader.onloadend = function (e) {
                            // setUploadedFileURLs(uploadedFileURLs);
                            SetSelectedFile((preValue) => {
                                return [
                                    ...preValue,
                                    {
                                        // id: shortid.generate(),
                                        id: res.data.data.id,
                                        filename: files.name,
                                        filetype: files.type,
                                        fileimage: reader.result,
                                        datetime: files.lastModifiedDate.toLocaleString('en-IN'),
                                        filesize: filesizes(files.size)
                                    }
                                ]
                            });
                        };
                        reader.onerror = function (e) {

                        }
                        reader.readAsDataURL(files);
                        let parsedUrl = new URL(url).pathname.substring(1);
                        parsedUrl = parsedUrl.substring(parsedUrl.indexOf('/') + 1);
                        // uploadedFileURLs.push({
                        //     id: null,
                        //     url: parsedUrl,
                        //     file_type: files[i].type,
                        //     file_name: files[i].name
                        // })
                        // setUploadedFileURLs(uploadedFileURLs);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        event.target.value = null;
    }
    return (

        <div className="fileupload-view">
            <div className="row justify-content-center m-0">
                <div className="col-md-12" style={{ padding: 0 }}>
                    <div className="card mt-5">
                        <div className="card-body">
                            <div className="kb-data-box">
                                <div className="kb-modal-data-title">
                                    <div className="kb-data-title">
                                        <h6>Shop Image/Video Upload</h6>
                                    </div>
                                </div>
                                <form onSubmit={FileUploadSubmit}>
                                    {Files && Files.length < 1 && <div className="kb-file-upload">
                                        <div className="file-upload-box">
                                            <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} />
                                            <span>Drag and drop or <span className="file-link">Choose your file</span></span>
                                        </div>
                                    </div>}
                                    <div className="kb-attach-box mb-3">
                                        {
                                            selectedfile.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={id}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p></p>
                                                            <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {Files && Files.length < 1 && <div className="kb-buttons-box">
                                        <button type="submit" className="btn btn-primary form-submit">Upload</button>
                                    </div>}
                                </form>
                                {Files.length > 0 ?
                                    <div className="kb-attach-box">
                                        <hr />
                                        {
                                            Files.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={index}>
                                                        {
                                                            // filetype.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                            <div className="file-image"> <img src={fileimage} alt="" /></div>
                                                            // :
                                                            // <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p><span>Size : {filesize}</span><span className="ml-3"> Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UploadShopItem;