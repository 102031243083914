import { Autocomplete, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setShowLoader } from "context";
import { setSnackBar } from "context";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import categoryService from "services/category.service";
import uploadFileService from "services/upload-file.service";
import authorsTableData from "../data/authorsTableData";

const ProductEdit = () => {
    const { options } = authorsTableData();
    const inputFile = useRef(null);
    const [image, setImage] = useState(null)

    const [controller, dispatch] = useMaterialUIController();

    const { categories } = controller;
    const { catid } = useParams();
    const [selectedCategory, setSelectedCategory] = useState(null);

    const { control, register, watch, formState: { errors }, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: selectedCategory
    });
    const defaultStatusProps = {
        options: [{ key: 1, value: "Active" },
        { key: 0, value: "Inactive" },
        ],
        getOptionLabel: (option) => option.value,
    }
    const [value, setStatusValue] = useState(null);
    const [descVal, setDescVal] = useState(null);
    const routeHistory = useHistory();

    const descPaste = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descBlur = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descChange = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }

    useEffect(() => {
        if (catid) {
            let category = categories.filter(el => el.id == catid);
            if (category && category.length > 0) {
                setSelectedCategory(category[0]);
                setValue("title", category[0]?.title);
                setValue("description", category[0]?.description);
                setValue("image_id", category[0]?.image_id);
                setValue("status", category[0]?.status);
                setStatusValue(category[0]?.status == 0 ? { key: 0, value: "Inctive" } : { key: 1, value: "Active" });
                setDescVal(category[0]?.description);
            } else {
                // routeHistory.push("/customers");
            }
        } else {
            // routeHistory.push("/customers");
        }
        return () => {
            setSelectedCategory(null)
        };
    }, [selectedCategory]);

    const handleCategory = (data) => {
        console.log(data)
        data.status = data.status == 0 || data.status == 1 ? data.status : data.status?.value == "Active" ? 1 : 0;
        setShowLoader(dispatch, true);
        if (catid) {
            categoryService
                .updateCategory(data, +catid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Category details updated!" });
                        routeHistory.push("/categories");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        } else {
            categoryService
                .addCategory(data, +catid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Category details added!", color: "success", icon: 'check', bgWhite: true });
                        routeHistory.push("/categories");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        }
    }

    let config = {
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
            },
            "/",
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "styles", groups: ["styles"] },
            { name: "colors", groups: ["colors"] },
            { name: "tools", groups: ["tools"] },
            "/",
            { name: "clipboard", groups: ["clipboard", "undo"] },
            { name: "others", groups: ["others"] },
            { name: "about", groups: ["about"] }
        ],
        removeButtons:
            "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
        fontSize_sizes: "16/16px;24/24px;48/48px;",
        font_names:
            "Arial/Arial, Helvetica, sans-serif;" +
            "Times New Roman/Times New Roman, Times, serif;" +
            "Verdana",
        allowedContent: true,
        height: 65
        // disableNativeSpellChecker: false
        // skin: "moono",
        // plugins:
        //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    };

    const onUploadClick = () => {
        inputFile.current.click();
    }

    const onImageChange = async (event) => {
        const files = event.target.files;
        if (files && files[0]) {
            setImage(URL.createObjectURL(files[0]));
            const { name, type } = files[0];
            const params = {
                key: name.includes('.') ? name.substring(0, name.indexOf('.')) : name,
                extension: type.includes('/') ? type.substring(type.indexOf('/') + 1) : type,
                belongsTo: 'category',
                purpose: 'category_img'
            };

            try {
                const res = await uploadFileService.getSignedUploadUrl(params);
                const url = res.data.data.signedURL;
                if (url) {
                    try {
                        const response = await uploadFileService.uploadFile(url, files[0]);
                        setValue("image_id", res.data.data.id);
                        const reader = new FileReader();
                        reader.onload = function (e) {
                            // setUploadedFileURLs(uploadedFileURLs);
                        };
                        reader.onerror = function (e) {

                        }
                        reader.readAsDataURL(files[0]);
                        let parsedUrl = new URL(url).pathname.substring(1);
                        parsedUrl = parsedUrl.substring(parsedUrl.indexOf('/') + 1);
                        // uploadedFileURLs.push({
                        //     id: null,
                        //     url: parsedUrl,
                        //     file_type: files[i].type,
                        //     file_name: files[i].name
                        // })
                        // setUploadedFileURLs(uploadedFileURLs);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        event.target.value = null;
    }

    const handleStatus = (newValue) => {
        setStatusValue(newValue);
        setValue("status", newValue);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <SimpleBlogCard
                                        image={image || selectedCategory?.image?.path || "https://bit.ly/3Hlw1MQ"}
                                        title="Category Image"
                                        action={{
                                            type: "upload",
                                        }}
                                    />
                                    <MDButton variant="gradient" color="dark" sx={{ mt: -5 }} fullWidth onClick={onUploadClick}>
                                        <input hidden accept="image/*" multiple type="file" ref={inputFile} onChange={onImageChange} />
                                        &nbsp;Upload
                                    </MDButton>
                                    {errors.image_id && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Category Image is {errors.image_id?.type === "pattern" ? "invalid" : "required"}</span>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Card>
                                <MDBox p={3}>
                                    <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">
                                        {"Category Information"}
                                    </MDTypography>
                                    <Grid container spacing={2} p={2}>
                                        <Grid xs={7} lg={7}>
                                            <MDInput type="text" {...register("title", { required: true })} name="title" label="Title" variant="outlined" fullWidth />
                                            {errors.title && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Title is {errors.title?.type === "pattern" ? "invalid" : "required"}</span>}
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={7}>
                                                <MDTypography color="text" fontSize="small" fontWeight="regular" sx={{ mt: 3, mb: 1 }} > Description</MDTypography>
                                                <Ckeditor
                                                    activeClass="p10"
                                                    config={config}
                                                    events={{
                                                        "blur": descBlur,
                                                        "afterPaste": descPaste,
                                                        "change": descChange,
                                                    }}
                                                    content={descVal}
                                                    name="description"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5} py={8} sx={{ mt: 3, mb: 1 }}>
                                                <Autocomplete
                                                    {...defaultStatusProps}
                                                    id="product-status"
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        handleStatus(newValue);
                                                        name = "status"
                                                    }}
                                                    renderInput={(params) => (
                                                        <MDInput {...params} label="Status" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <input type="hidden" {...register("image_id", {required: true})} label="" name="image_id" />
                                        <Grid item xs={12} md={12} py={2}>
                                            <Link onClick={handleSubmit((d) => handleCategory(d))} >
                                                <MDButton variant="gradient" color="dark" fullWidth>
                                                    {catid ? 'Update' : 'Add'}
                                                </MDButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
};

export default ProductEdit;