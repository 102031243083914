import axios from "../util/axios";

const fetchAllCategories = () => axios.get("categories");

const addCategory = (data) => axios.post("categories/create", data);

const updateCategory = (data, id) => axios.put("categories/" + id, data);

const removeCategory = (id) => axios.delete("categories/" + id);

export default {
  fetchAllCategories,
  addCategory,
  updateCategory,
  removeCategory
};
