import { Autocomplete, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setShowLoader } from "context";
import { setSnackBar } from "context";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import categoryService from "services/category.service";
import crmService from "services/crm.service";
import journalService from "services/journal.service";
import shopService from "services/shop.service";
import uploadFileService from "services/upload-file.service";
import authorsTableData from "../data/authorsTableData";
import UploadShopItem from "./uploadShopItem";

const ShopEdit = () => {
    const { indexes, productList } = authorsTableData();
    const inputFile = useRef(null);
    const [image, setImage] = useState(null)

    const [controller, dispatch] = useMaterialUIController();

    const { shopItems } = controller;
    const { sid } = useParams();
    const [selectedItem, setSelectedItem] = useState(null);

    const { control, register, watch, formState: { errors }, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: selectedItem
    });
    const defaultProductList = {
        options: productList,
        getOptionLabel: (option) => option.value,
    }
    const defaultIndexProps = {
        options: indexes,
        getOptionLabel: (option) => option.value,
    }
    const [value, setProductName] = useState(null);
    const [descVal, setDescVal] = useState(null);
    const routeHistory = useHistory();
    const [Files, SetFiles] = useState([]);
    const [productPosition, setProductPosition] = useState(null);

    const descPaste = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descBlur = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descChange = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    useEffect(() => {
        console.log(Files)
        Files && Files.length > 0 && setValue("image_id", Files[0].id);
    }, [Files]);

    useEffect(() => {
        console.log('sid', sid)
        if (sid) {
            let shop = shopItems?.filter(el => el.id == sid);
            if (shop && shop.length > 0) {
                setSelectedItem(shop[0]);
                setValue("title", shop[0]?.title);
                setValue("product_id", shop[0]?.product_id);
                setValue("description", shop[0]?.description);
                setValue("image_id", shop[0]?.image_id);
                setValue("position", shop[0]?.position);
                setDescVal(shop[0]?.description);
                setProductName({ key: shop[0]?.products?.id, value: shop[0]?.products?.name });
                setProductPosition({ key: shop[0]?.position, value: shop[0]?.position });

                let tempArr = [];
                shop[0]?.images && tempArr.push({
                    id: shop[0]?.images.id,
                    filename: shop[0]?.images.original_name,
                    filetype: shop[0]?.images.extension,
                    fileimage: shop[0]?.images.path,
                    datetime: shop[0]?.images.updated_at.toLocaleString('en-IN'),
                    filesize: shop[0]?.images.size
                })
                console.log(tempArr)
                SetFiles(tempArr);

            } else {
                // routeHistory.push("/customers");
            }
        } else {
            // routeHistory.push("/customers");
        }
        return () => {
            setSelectedItem(null)
        };
    }, [selectedItem]);

    const handleCRM = (data) => {
        console.log(data)
        data.source_type = data.source_type == "image" || data.source_type == "video" || data.source_type == "text" ? data.source_type : data.source_type?.key;
        data.image_id = data.image_id || null;
        setShowLoader(dispatch, true);
        if (sid) {
            shopService
                .updateShopItem(data, +sid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Journal updated!" });
                        routeHistory.push("/shop");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        } else {
            shopService
                .addShopItem(data, +sid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Journal added!", color: "success", icon: 'check', bgWhite: true });
                        routeHistory.push("/shop");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        }
    }

    let config = {
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
            },
            "/",
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "styles", groups: ["styles"] },
            { name: "colors", groups: ["colors"] },
            { name: "tools", groups: ["tools"] },
            "/",
            { name: "clipboard", groups: ["clipboard", "undo"] },
            { name: "others", groups: ["others"] },
            { name: "about", groups: ["about"] }
        ],
        removeButtons:
            "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
        fontSize_sizes: "16/16px;24/24px;48/48px;",
        font_names:
            "Arial/Arial, Helvetica, sans-serif;" +
            "Times New Roman/Times New Roman, Times, serif;" +
            "Verdana",
        allowedContent: true,
        height: 65
        // disableNativeSpellChecker: false
        // skin: "moono",
        // plugins:
        //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    };


    const handleProductName = (newValue) => {
        console.log(newValue)
        setProductName(newValue);
        setValue("source_type", newValue.key);
    }

    const handlePosition = (newValue) => {
        console.log(newValue)
        setProductPosition(newValue);
        setValue("position", newValue.key);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <MDBox p={3}>
                                    <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">
                                        {"Shop Item Information"}
                                    </MDTypography>
                                    <Grid container spacing={2} p={2}>
                                        <Grid xs={12} lg={12}>
                                            <MDInput type="text" {...register("title")} name="title" label="Title" variant="outlined" fullWidth />
                                        </Grid>
                                        <Grid xs={12} lg={12}>
                                            <Autocomplete
                                                {...defaultProductList}
                                                id="product-name"
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    handleProductName(newValue);
                                                    name = "product_id"
                                                }}
                                                renderInput={(params) => (
                                                    <MDInput {...params} label="Product Name" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={12} lg={12}>
                                            <Autocomplete
                                                {...defaultIndexProps}
                                                id="product-index"
                                                value={productPosition}
                                                onChange={(event, newValue) => {
                                                    handlePosition(newValue);
                                                    name = "product_index"
                                                }}
                                                renderInput={(params) => (
                                                    <MDInput {...params} label="Product Position" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={12} lg={12}>
                                            <input type="hidden" {...register("image_id")} label="" name="image_id" />
                                            <Grid item xs={12} md={12}>
                                                <UploadShopItem Files={Files} SetFiles={SetFiles} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                                <MDTypography color="text" fontSize="small" fontWeight="regular" sx={{ mt: 3, mb: 1 }} > Description</MDTypography>
                                                <Ckeditor
                                                    activeClass="p10"
                                                    config={config}
                                                    events={{
                                                        "blur": descBlur,
                                                        "afterPaste": descPaste,
                                                        "change": descChange,
                                                    }}
                                                    content={descVal}
                                                    name="description"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} py={2}>
                                            <Link onClick={handleSubmit((d) => handleCRM(d))} >
                                                <MDButton variant="gradient" color="dark" fullWidth>
                                                    {sid ? 'Update' : 'Add'}
                                                </MDButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
};

export default ShopEdit;