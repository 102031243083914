import { Autocomplete, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setShowLoader } from "context";
import { setSnackBar } from "context";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import categoryService from "services/category.service";
import crmService from "services/crm.service";
import referenceService from "services/reference.service";
import uploadFileService from "services/upload-file.service";
import authorsTableData from "../data/authorsTableData";
import UploadCRM from "./uploadCRM";

const CRMEdit = () => {
    const { options, indexes, productList } = authorsTableData();
    const inputFile = useRef(null);
    const [image, setImage] = useState(null)

    const [controller, dispatch] = useMaterialUIController();

    const { crmData } = controller;
    const { crmid } = useParams();
    const [selectedCRM, setSelectedCRM] = useState(null);

    const { control, register, watch, formState: { errors }, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: selectedCRM
    });

    const [value, setPageValue] = useState(null);
    const [descVal, setDescVal] = useState(null);
    const [titleVal, setTitleVal] = useState(null);
    const routeHistory = useHistory();
    const [Files, SetFiles] = useState([]);

    const [productname, setProductName] = useState(null);

    const [productPosition, setProductPosition] = useState(null);

    const defaultStatusProps = {
        options: options,
        getOptionLabel: (option) => option.value,
    }

    const defaultProductList = {
        options: productList,
        getOptionLabel: (option) => option.value,
    }
    const defaultIndexProps = {
        options: indexes,
        getOptionLabel: (option) => option.value,
    }

    const descPaste = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descBlur = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const descChange = (evnt) => {
        setValue("description", evnt.editor.getData());
        setDescVal(evnt.editor.getData());

    }
    const titlePaste = (evnt) => {
        setValue("title", evnt.editor.getData());
        setTitleVal(evnt.editor.getData());

    }
    const titleBlur = (evnt) => {
        setValue("title", evnt.editor.getData());
        setTitleVal(evnt.editor.getData());

    }
    const titleChange = (evnt) => {
        setValue("title", evnt.editor.getData());
        setTitleVal(evnt.editor.getData());

    }
    useEffect(() => {
        console.log('crmid', crmid, options)
        if (crmid) {
            let crm = crmData?.filter(el => el.id == crmid);
            if (crm && crm.length > 0) {
                setSelectedCRM(crm[0]);
                setValue("page_id", crm[0]?.page_id);
                setValue("description", crm[0]?.description);
                setValue("title", crm[0]?.title);
                setValue("image_id", crm[0]?.image_id);
                setDescVal(crm[0]?.description);
                setTitleVal(crm[0]?.title);

                setValue("product_id", crm[0]?.product_id);
                setValue("position", crm[0]?.position);
                setProductName({ key: crm[0]?.products?.id, value: crm[0]?.products?.name });
                setProductPosition({ key: crm[0]?.position, value: crm[0]?.position });

                let tempArr = [];
                crm[0]?.images && tempArr.push({
                    id: crm[0]?.images.id,
                    filename: crm[0]?.images.original_name,
                    filetype: crm[0]?.images.extension,
                    fileimage: crm[0]?.images.path,
                    datetime: crm[0]?.images.updated_at.toLocaleString('en-IN'),
                    filesize: crm[0]?.images.size
                })
                console.log(tempArr)
                SetFiles(tempArr);

                referenceService
                    .fetchAllOptions()
                    .then(
                        (res) => {
                            // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
                            if (res && res.data) {
                                console.log(res.data, crm[0]?.page_id)
                                res.data.forEach(el =>
                                    el.id == crm[0]?.page_id ? setPageValue({ key: crm[0]?.page_id, value: el.page_name }) : '');
                            }
                        },
                        (error) => console.error(error)
                    )

            } else {
                // routeHistory.push("/customers");
            }
        } else {
            // routeHistory.push("/customers");
        }
        return () => {
            setSelectedCRM(null)
        };
    }, [selectedCRM]);

    const handleCRM = (data) => {
        console.log(data)
        // data.page_name = data.page_name == "landing" || data.page_name == "contact_us" ? data.page_name : data.page_name?.key;
        data.image_id = data.image_id;
        setShowLoader(dispatch, true);
        if (crmid) {
            crmService
                .updateCRM(data, +crmid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "CRM details updated!" });
                        routeHistory.push("/crm");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        } else {
            crmService
                .addCRM(data, +crmid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "CRM details added!", color: "success", icon: 'check', bgWhite: true });
                        routeHistory.push("/crm");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        }
    }

    let config = {
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
            },
            "/",
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "styles", groups: ["styles"] },
            { name: "colors", groups: ["colors"] },
            { name: "tools", groups: ["tools"] },
            "/",
            { name: "clipboard", groups: ["clipboard", "undo"] },
            { name: "others", groups: ["others"] },
            { name: "about", groups: ["about"] }
        ],
        removeButtons:
            "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
        fontSize_sizes: "16/16px;24/24px;48/48px;",
        font_names:
            "Arial/Arial, Helvetica, sans-serif;" +
            "Times New Roman/Times New Roman, Times, serif;" +
            "Verdana",
        allowedContent: true,
        height: 65
        // disableNativeSpellChecker: false
        // skin: "moono",
        // plugins:
        //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    };

    const onUploadClick = () => {
        inputFile.current.click();
    }

    const onImageChange = async (event) => {
        const files = event.target.files;
        if (files && files[0]) {
            setImage(URL.createObjectURL(files[0]));
            const { name, type } = files[0];
            const params = {
                key: name.includes('.') ? name.substring(0, name.indexOf('.')) : name,
                extension: type.includes('/') ? type.substring(type.indexOf('/') + 1) : type,
                belongsTo: 'crm',
                purpose: 'crm_img'
            };

            try {
                const res = await uploadFileService.getSignedUploadUrl(params);
                const url = res.data.data.signedURL;
                if (url) {
                    try {
                        const response = await uploadFileService.uploadFile(url, files[0]);
                        setValue("image_id", res.data.data.id);
                        const reader = new FileReader();
                        reader.onload = function (e) {
                            // setUploadedFileURLs(uploadedFileURLs);
                        };
                        reader.onerror = function (e) {

                        }
                        reader.readAsDataURL(files[0]);
                        let parsedUrl = new URL(url).pathname.substring(1);
                        parsedUrl = parsedUrl.substring(parsedUrl.indexOf('/') + 1);
                        // uploadedFileURLs.push({
                        //     id: null,
                        //     url: parsedUrl,
                        //     file_type: files[i].type,
                        //     file_name: files[i].name
                        // })
                        // setUploadedFileURLs(uploadedFileURLs);
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        event.target.value = null;
    }

    const handlePageName = (newValue) => {
        console.log(newValue)
        if (newValue) {
            setPageValue(newValue);
            setValue("page_id", newValue.key);
        }
        else {
            setValue("page_id", undefined);
        }
    }

    useEffect(() => {
        console.log(Files)
        Files && Files.length > 0 && setValue("image_id", Files[0].id);
    }, [Files]);

    const handleProductName = (newValue) => {
        console.log(newValue)
        if (newValue) {
            setProductName(newValue);
            setValue("product_id", newValue.key);
        }
        else {
            setValue("product_id", undefined);
        }
    }

    const handlePosition = (newValue) => {
        console.log(newValue)
        if (newValue) {
            setProductPosition(newValue);
            setValue("position", newValue.key);
        }
        else {
            setValue("position", undefined);
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {/* <SimpleBlogCard
                                        image={image || selectedCRM?.images?.path || "https://bit.ly/3Hlw1MQ"}
                                        title="CRM Image"
                                        action={{
                                            type: "upload",
                                        }}
                                    /> */}
                                    <UploadCRM Files={Files} SetFiles={SetFiles} />

                                    {/* <MDButton variant="gradient" color="dark" sx={{ mt: -5 }} fullWidth onClick={onUploadClick}>
                                        <input hidden accept="image/*" multiple type="file" ref={inputFile} onChange={onImageChange} />
                                        &nbsp;Upload
                                    </MDButton> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Card>
                                <MDBox p={3}>
                                    <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">
                                        {"CRM Information"}
                                    </MDTypography>
                                    <Grid container spacing={2} p={2}>
                                        <Grid xs={12} lg={12}>
                                            <Autocomplete
                                                {...defaultStatusProps}
                                                value={value}
                                                id="page_id"
                                                // onInputChange={(event, newInputValue, reason) => {
                                                //     if (reason === 'reset') {
                                                //         handlePageName('')
                                                //         return
                                                //     } else {
                                                //         handlePageName(newInputValue);
                                                //     }
                                                // }}
                                                onChange={(event, newValue) => {
                                                    handlePageName(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <MDInput {...params} label="Page Name" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={12} lg={12}>
                                            <Autocomplete
                                                {...defaultProductList}
                                                id="product-name"
                                                value={productname}
                                                // onInputChange={(event, newInputValue, reason) => {
                                                //     if (reason === 'reset') {
                                                //         handleProductName('')
                                                //         return
                                                //     } else {
                                                //         handleProductName(newInputValue);
                                                //     }
                                                // }}
                                                onChange={(event, newValue) => {
                                                    handleProductName(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <MDInput {...params} label="Product Name" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={12} lg={12}>
                                            <Autocomplete
                                                {...defaultIndexProps}
                                                id="product-index"
                                                value={productPosition}
                                                // onInputChange={(event, newInputValue, reason) => {
                                                //     if (reason === 'reset') {
                                                //         handlePosition('')
                                                //         return
                                                //     } else {
                                                //         handlePosition(newInputValue);
                                                //     }
                                                // }}
                                                onChange={(event, newValue) => {
                                                    handlePosition(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <MDInput {...params} label="Product Position" variant="outlined" sx={{ mt: 3, mb: 1 }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                                <MDTypography color="text" fontSize="small" fontWeight="regular" sx={{ mt: 3, mb: 1 }} > Title (if applicable)</MDTypography>
                                                <Ckeditor
                                                    activeClass="p10"
                                                    config={config}
                                                    events={{
                                                        "blur": titleBlur,
                                                        "afterPaste": titlePaste,
                                                        "change": titleChange,
                                                    }}
                                                    content={titleVal}
                                                    name="title"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                                <MDTypography color="text" fontSize="small" fontWeight="regular" sx={{ mt: 3, mb: 1 }} > Description</MDTypography>
                                                <Ckeditor
                                                    activeClass="p10"
                                                    config={config}
                                                    events={{
                                                        "blur": descBlur,
                                                        "afterPaste": descPaste,
                                                        "change": descChange,
                                                    }}
                                                    content={descVal}
                                                    name="description"
                                                />
                                            </Grid>
                                        </Grid>
                                        <input type="hidden" {...register("image_id")} label="" name="image_id" />
                                        <Grid item xs={12} md={12} py={2}>
                                            <Link onClick={handleSubmit((d) => handleCRM(d))} >
                                                <MDButton variant="gradient" color="dark" fullWidth>
                                                    {crmid ? 'Update' : 'Add'}
                                                </MDButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
};

export default CRMEdit;