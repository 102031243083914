import { Autocomplete, Card, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setShowLoader } from "context";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef, useState } from "react";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import categoryService from "services/category.service";
import couponService from "services/coupon.service";
import authorsTableData from "../data/authorsTableData";

import './style.css';
import "react-datepicker/dist/react-datepicker.css";
import { dark } from "@mui/material/styles/createPalette";
import moment from "moment";
import { setSnackBar } from "context";

const CouponEdit = () => {
    const [controller, dispatch] = useMaterialUIController();

    const { coupons } = controller;
    const { cid } = useParams();
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const { control, register, watch, formState: { errors }, handleSubmit, setValue, reset } = useForm({
        mode: "onChange",
        defaultValues: selectedCoupon
    });
    const defaultUseProps = {
        options: [{ key: "product", value: "Product" },
        { key: "bundle", value: "Bundle" },
        { key: "all", value: "All" }
        ],
        getOptionLabel: (option) => option.value,
    }
    const defaultTypeProps = {
        options: [{ key: "currency", value: "Currency" },
        { key: "percent", value: "Percent" },
        { key: "free", value: "Free" }
        ],
        getOptionLabel: (option) => option.value,
    }
    const [useForValue, setUseForValue] = useState(null);
    const [typeValue, setTypeValue] = useState(null);

    const [descVal, setDescVal] = useState(null);
    const routeHistory = useHistory();
    const [validFrom, setValidFrom] = useState(new Date());
    const [validUntil, setValidUntil] = useState(new Date());
    const [recursive, setRecursive] = useState(0);
    const NUMERIC_REGEX = /^[0-9]+$/;

    useEffect(() => {
        if (cid) {
            let coupon = coupons.filter(el => el.id == cid);
            if (coupon && coupon.length > 0) {
                setSelectedCoupon(coupon[0]);
                setValue("code", coupon[0]?.code);
                setValue("quantity", coupon[0]?.quantity);
                setValue("discount_value", coupon[0]?.discount_value);
                setValue("can_use_for", coupon[0]?.can_use_for);
                setValue("type", coupon[0]?.type);
                setValue("valid_from", new Date(coupon[0]?.valid_from));
                setValue("valid_until", new Date(coupon[0]?.valid_until));
                setValue("recursive", coupon[0]?.recursive);
                setTypeValue(defaultTypeProps.options.filter(el => el.key == coupon[0]?.type)[0]);
                setUseForValue(defaultUseProps.options.filter(el => el.key == coupon[0]?.can_use_for)[0]);
                setValidFrom(new Date(coupon[0]?.valid_from));
                setValidUntil(new Date(coupon[0]?.valid_until));
                setRecursive(coupon[0]?.recursive);
                setDescVal(coupon[0]?.description);
            } else {
                // routeHistory.push("/customers");
            }
        } else {
            // routeHistory.push("/customers");
        }
        return () => {
            setSelectedCoupon(null)
        };
    }, [selectedCoupon]);

    const handleCoupon = (data) => {
        const format = "YYYY-MM-DD";
        data.type = data.type?.key ? data.type.key : data.type;
        data.can_use_for = data.can_use_for?.key ? data.can_use_for.key : data.can_use_for;
        data.valid_from = moment(validFrom).format(format);
        data.valid_until = moment(validUntil).format(format);
        data.recursive = recursive;

        setShowLoader(dispatch, true);
        if (cid) {
            couponService
                .updateCoupon(data, +cid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Coupon Updated!" });
                        routeHistory.push("/coupons");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        } else {
            couponService
                .addCoupon(data, +cid)
                .then(
                    (res) => {
                        setSnackBar(dispatch, { title: "Notification", content: "Coupon Added!", color: "success", icon: 'check', bgWhite: true });
                        routeHistory.push("/coupons");
                    },
                    (error) => console.error(error)
                ).then(() => setShowLoader(dispatch, false));
        }
    }

    const handleUseFor = (newValue) => {
        setUseForValue(newValue);
        setValue("can_use_for", newValue);
    }

    const handleType = (newValue) => {
        setTypeValue(newValue);
        setValue("type", newValue);
        if (newValue?.key == "free") {
            setValue("discount_value", undefined);
        }
    }

    const handleValidFrom = (date) => {
        setValidFrom(date);
        setValidUntil(date);
    }

    const handleValidUntil = (date) => {
        setValidUntil(date);
    }

    const handleChange = (event) => {
        setRecursive(event.target.value);
        setValue("recursive", event.target.value);
        if (event.target.value == 1) {
            setValue("quantity", undefined);
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={3}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} lg={12}>
                            <Card>
                                <MDBox p={3}>
                                    <MDTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="bold">
                                        {"Coupon Information"}
                                    </MDTypography>
                                    <Grid container spacing={1} p={6}>
                                        <Grid container spacing={2} px={4}>
                                            <Grid xs={12} lg={3.8}>
                                                <MDInput type="text" {...register("code", { required: true })} name="code" label="Discount Code" variant="outlined" fullWidth />
                                                {errors.code && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}> Discount Code is {errors.code?.type === "pattern" ? "invalid" : "required"}</span>}
                                            </Grid>
                                            <Grid xs={12} lg={0.3}>
                                            </Grid>
                                            <Grid xs={12} lg={3.8}>
                                                <Autocomplete
                                                    {...defaultTypeProps}
                                                    id="coupon-type"
                                                    value={typeValue}
                                                    onChange={(event, newValue) => {
                                                        handleType(newValue);
                                                        name = "type"
                                                    }}
                                                    renderInput={(params) => (
                                                        <MDInput {...params} label="Type" variant="outlined" sx={{ mb: 1 }} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={12} lg={0.3}>
                                            </Grid>
                                            <Grid xs={12} lg={3.8}>
                                                <MDInput type="number" InputProps={{
                                                    inputProps: { min: 0 }
                                                }}
                                                    {...register("discount_value", { pattern: NUMERIC_REGEX })} name="discount_value" label="Discount Value" variant="outlined" fullWidth disabled={watch("type")?.key == "free"} />
                                                {errors.discount_value && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Dicount Value is {errors.discount_value?.type === "pattern" ? "invalid" : "required"}</span>}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} px={2}>
                                            <Grid item xs={12} md={4}>
                                                <FormLabel id="coupon-recursive" sx={{ fontSize: 12 }}>Is Recursive?</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="coupon-recursive"
                                                    name="recursive"
                                                    value={recursive}
                                                    onChange={(data) => handleChange(data)}
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} md={4} >

                                                <Autocomplete
                                                    {...defaultUseProps}
                                                    id="coupon-use-for"
                                                    value={useForValue}
                                                    onChange={(event, newValue) => {
                                                        handleUseFor(newValue);
                                                        name = "can_use_for"
                                                    }}
                                                    renderInput={(params) => (
                                                        <MDInput {...params} label="Can Use For" variant="outlined" sx={{ mt: 3.8, mb: 1, ml: -1 }} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} >
                                                <MDInput type="number" {...register("quantity", { pattern: NUMERIC_REGEX })} sx={{ mt: 4, mb: 1 }} name="quantity" label="Quantity" variant="outlined" fullWidth disabled={watch("recursive") == 1} />
                                                {errors.quantity && <span style={{ fontWeight: 100, fontSize: "small", color: "red" }}>Quantity is {errors.quantity?.type === "pattern" ? "invalid" : "required"}</span>}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} px={2}>
                                            <Grid item xs={12} md={4}>

                                                <MDTypography display="inline" sx={{ fontSize: 12 }} textTransform="capitalize" >
                                                    {"Valid From"}
                                                </MDTypography>
                                                <ReactDatePicker
                                                    className="reactDatePicker"
                                                    showIcon selected={validFrom} onChange={(date) => handleValidFrom(date)} />

                                            </Grid>
                                            <Grid item xs={12} md={4} >
                                                <MDTypography display="inline" sx={{ fontSize: 12 }} textTransform="capitalize" >
                                                    {"Valid Until"}
                                                </MDTypography>
                                                <ReactDatePicker
                                                    className="reactDatePicker" minDate={validFrom}
                                                    showIcon selected={validUntil} onChange={(date) => handleValidUntil(date)} />
                                            </Grid>
                                            <Grid item xs={12} md={4} >
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} py={2} sx={{ mt: 4 }}>
                                            <Link onClick={handleSubmit((d) => handleCoupon(d))} >
                                                <MDButton variant="gradient" color="dark" fullWidth>
                                                    {cid ? 'Update' : 'Add'}
                                                </MDButton>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
};

export default CouponEdit;