
// @mui material components
import { Autocomplete, Card, Grid, Icon, Tooltip } from "@mui/material";
import moment from 'moment';

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Britacel example components
import TimelineItem from "examples/Timeline/TimelineItem";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import '../style.css';
import { useForm } from "react-hook-form";
import { setShowLoader } from "context";
import orderService from "services/order.service";
import { useHistory } from "react-router";



function ShipmentTrackOrder(props) {
  const { createdt, updatedt, status, oid, dispatch } = props;
  console.log({ status })
  const { watch, setValue } = useForm({
    mode: "onChange",
  });

  const routeHistory = useHistory();

  const getStatus = (key) => {
    let value = "New";
    if (key === 'pickup') {
      value = 'Pickup'
    }
    if (key === 'in_transit') {
      value = 'In Transit'

    } else if (key === 'out_for_delivery') {
      value = 'Out For Delivery';
    } else if (key == 'delivered') {
      value = 'Delivered';
    }
    return value;
  }
  



  useEffect(() => {
    getStatus(status);
    setValue("order_status", true);
  }, [status]);

  return (
    <Card sx={{
      height: "100%",
      width: {
        sx: 1.0, // 100%
        sm: 320,
        md: 450,
      },
    }}
    >
      <MDBox pt={2} px={5}>
        <Grid container spacing={3}>
          <Grid item xs={9} lg={9}>
            <MDTypography variant="h6" fontWeight="medium">
              Shipment Order Status
            </MDTypography>
          </Grid>
          
        </Grid>
      </MDBox>
      <MDBox px={5}>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={5.5}>
            <TimelineItem
              className="iconName"
              color="success"
              icon="task_alt"
              title="New"
              dateTime={moment(createdt).format("LLL")}
            />
            <TimelineItem
              className="iconName"
              color={status === "pickup" ? "success" : "warning"}
              icon="inventory"
              title="Pickup"
              dateTime={status === "pickup" ? moment(updatedt).format("LLL") : "-"}
            />
            
            <TimelineItem
              className="iconName"
              color={status === "in_transit" ? "success" : "warning"}
              icon="directions_transit"
              title="In Transit"
              dateTime={status === "in_transit" ? moment(updatedt).format("LLL") : "-"}
            />
            <TimelineItem
              className="iconName"
              color={status === "out_for_delivery" ? "success" : "light"}
              icon="local_shipping"
              title="Out For Delivery"
              dateTime={status === "out_for_delivery" ? (moment(updatedt).format("LLL")) : "-"}
              lastItem
            />

            <TimelineItem
              className="iconName"
              color={status === "delivered" ? "success" : "light"}
              icon="shopping_cart"
              title="Delivered"
              dateTime={status === "delivered" ? (moment(updatedt).format("LLL")) : "-"}
              lastItem
            />
          </Grid>
          
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ShipmentTrackOrder;
