import axios from "../util/axios";

const fetchAllCoupons = () => axios.get("coupons");

const addCoupon = (data) => axios.post("coupons/create", data);

const updateCoupon = (data, id) => axios.put("coupons/" + id, data);

const removeCoupon = (id) => axios.delete("coupons/" + id);

export default {
  fetchAllCoupons,
  addCoupon,
  updateCoupon,
  removeCoupon
};
