// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Britacel example components
import TimelineItem from "examples/Timeline/TimelineItem";
import moment from "moment";
import { useEffect } from "react";

function OrdersOverview(props) {

  const { overview } = props;

  useEffect(() => {
    console.log(overview)
  }, [overview]);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Orders overview
        </MDTypography>
      </MDBox>
      {overview?.map((el, index) => {
        return (<MDBox py={1} px={2}>
          <TimelineItem
            color="info"
            icon="shopping_cart"
            title={`Payment done for order ${el.orderid}`}
            dateTime={moment(el.date).format("LLL")}
            lastItem={(overview.length - 1) == index}
          />
        </MDBox>)
      })}
    </Card>
  );
}

export default OrdersOverview;
