import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setShopList, setSnackBar } from "context";
import categoryService from "services/category.service";
import CustomizedDialogs from "components/MDDialog";
import { Link, useParams } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";
import shopService from "services/shop.service";
import productService from "services/product.service";
import { setProductList } from "context";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { shopItems, products, isDelete } = controller;
  const [open, setOpen] = useState(false);
  const { sid } = useParams();
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getShopList = () => {
    setShowLoader(dispatch, true);

    productService
      .fetchAllProducts()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setProductList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
    shopService
      .fetchAllShopItems()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setShopList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getShopList();
  }, [isDelete]);

  const CRM = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Description = ({ description }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <CustomizedDialogs
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        buttonname="description"
        buttontext="close"
        body={description}
        title="Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    </MDBox>
  );

  const Image = ({ image, name }) => (
    <MDAvatar src={image} name={name} size="sm" />
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const generateRow = () => {
    const rows = [];
    console.log(shopItems)
    shopItems?.forEach((el) => {
      rows.push({
        title: <CRM name={el.title} />,
        description: <Description description={el.description} />,
        product_name: <CRM name={el?.products?.name} />,
        image: el.source_type != "video" ? <Image image={el.images?.path} name={el.images?.original_name} /> : <a href={el.images?.path} className="file-action-btn" download={el.images?.path}>Link</a>,
        position: <CRM name={el.position} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/shop/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
            <ActionDialog
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              buttontext="delete"
              buttonname="Delete"
              body={"Are you sure you want to delete this shop item ?"}
              open={open}
              setOpen={() => setOpenId(el.id)}

              onClick={() => setOpenId(el.id)}
              sid={selectedId}
              title="Delete Shop Item&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </MDBox>
        ),
      });
    });
    return rows;
  };

  const generateIndexList = () => {
    const prodIndex = [];
    let tempArr = [];
    if (shopItems.length > 0) {

      let tempArr = [...Array(shopItems.length).keys()];

      // shopItems.forEach(el => {
      //   let index = tempArr.indexOf(el.product_index ? +el.product_index : -1);
      //   if (index >= 0) {
      //     tempArr.splice(index, 1);
      //   }
      // });
      tempArr.forEach(el => prodIndex.push({ key: el + 1, value: el + 1 }));
      // let myArray = prodIndex.filter( ( el ) => el.key.inclues );

      // });
    } else {
      prodIndex.push({ key: shopItems.length + 1, value: shopItems.length + 1 });
    }
    if (!sid && shopItems.length > 0) {
      prodIndex.push({ key: shopItems.length + 1, value: shopItems.length + 1 });
    }

    return prodIndex;
  };

  const generateProductList = () => {
    let productList = []
    products.forEach(el => productList.push({ "key": el.id, "value": el.name }))

    return productList;
  }
  return {
    columns: [
      { Header: "title", accessor: "title", align: "left" },
      { Header: "description", accessor: "description", align: "left" },
      { Header: "product Name", accessor: "product_name", align: "left" },
      { Header: "image/video", accessor: "image", align: "center" },
      { Header: "position", accessor: "position", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
    indexes: generateIndexList(),
    productList: generateProductList(),
  };
};

export default authorsTableData;
