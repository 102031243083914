import { useEffect } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useMaterialUIController, setShowLoader, setEnquiryList, setSnackBar } from "context";
import CustomizedDialogs from "components/MDDialog";
import enquiryService from "services/enquiry.service";
import MDBadge from "components/MDBadge";
import { setReplyRequest } from "context";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { enquiries } = controller;

  const getEnquiryList = () => {
    setShowLoader(dispatch, true);

    enquiryService
      .fetchAllEnquiries()
      .then(
        (res) => {
          setEnquiryList(dispatch, res.data);
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getEnquiryList();
  }, []);

  const EnquiryName = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const Message = ({ message }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <CustomizedDialogs
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        buttonname="Message"
        buttontext="close"
        body={message}
        title="Enquiry Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const handleReply = (selected) => {
    console.log(selected);
    setReplyRequest(dispatch,selected);
  }
  const generateRow = () => {
    const rows = [];
    enquiries.forEach((el) => {
      rows.push({
        full_name: <EnquiryName name={el.full_name} />,
        message: <Message message={el.message} />,
        email: <EnquiryName name={el.email} />,
        created: <Created createDate={el.created_at} />,
        action: (<>
          <MDBox ml={-1}>
            {/* <Link to={`/customers/edit/${el.id}`} > */}
            <MDBadge
              badgeContent="reply"
              color="info"
              variant="gradient"
              size="sm"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleReply(el)}
            />
            {/* </Link> */}
          </MDBox>

        </>
        )
      });
    });
    return rows;
  };


  return {
    columns: [
      { Header: "Full Name", accessor: "full_name", width: "35%", align: "left" },
      { Header: "message", accessor: "message", align: "left" },
      { Header: "email", accessor: "email", align: "center" },
      { Header: "create date", accessor: "created", align: "left" },
      { Header: "Action", accessor: "action", align: "left" }
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
