// @mui material components
import Card from "@mui/material/Card";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";

function BillingInformation(props) {
  const { addr_line1, addr_line2, name, mobile, email, city, state, pincode } = props;
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Billing Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
            name={name}
            mobile={mobile}
            email={email}
            line1={addr_line1}
            line2={addr_line2}
            city={city}
            state={state}
            pincode={pincode}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
