/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Britacel main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Britacel reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "SHOW_LOADER": {
      return { ...state, showLoader: action.value };
    }
    case "CUSTOMER_LIST": {
      return { ...state, customers: action.value };
    }
    case "CATEGORY_LIST": {
      return { ...state, categories: action.value };
    }
    case "PRODUCT_LIST": {
      return { ...state, products: action.value };
    }
    case "ORDER_LIST": {
      return { ...state, orders: action.value };
    }
    case "SNACKBAR_DATA": {
      return { ...state, snackBar: action.value };
    }
    case "COUPON_LIST": {
      return { ...state, coupons: action.value };
    }
    case "ENQUIRY_LIST": {
      return { ...state, enquiries: action.value };
    }
    case "SUBSCRIBE_LIST": {
      return { ...state, subscribes: action.value };
    }
    case "IS_DELETE": {
      return { ...state, isDelete: action.value };
    }
    case "PROFILE_URL": {
      return { ...state, profileUrl: action.value };
    }
    case "CRMDATA_LIST": {
      return { ...state, crmData: action.value };
    }
    case "JOURNAL_LIST": {
      return { ...state, journals: action.value };
    }
    case "SHOP_LIST": {
      return { ...state, shopItems: action.value };
    }
    case "REPLY_REQUEST": {
      return { ...state, reply_request: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Britacel context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "dark",
    transparentNavbar: false,
    fixedNavbar: true,
    openConfigurator: false,
    layout: "dashboard",
    darkMode: false,
    showLoader: false,
    customers: [],
    categories: [],
    products: [],
    orders: [],
    snackBar: {},
    coupons: [],
    enquiries: [],
    subscribes: [],
    isDelete: false,
    profileUrl: null,
    crmData: [],
    journals: [],
    shopItems: [],
    reply_request: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Britacel custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setShowLoader = (dispatch, value) => dispatch({ type: "SHOW_LOADER", value });
const setCustomerList = (dispatch, value) => dispatch({ type: "CUSTOMER_LIST", value });
const setCategoryList = (dispatch, value) => dispatch({ type: "CATEGORY_LIST", value });
const setProductList = (dispatch, value) => dispatch({ type: "PRODUCT_LIST", value });
const setOrderList = (dispatch, value) => dispatch({ type: "ORDER_LIST", value });
const setSnackBar = (dispatch, value) => dispatch({ type: "SNACKBAR_DATA", value });
const setCouponList = (dispatch, value) => dispatch({ type: "COUPON_LIST", value });
const setEnquiryList = (dispatch, value) => dispatch({ type: "ENQUIRY_LIST", value });
const setSubcribeList = (dispatch, value) => dispatch({ type: "SUBSCRIBE_LIST", value });
const setIsDelete = (dispatch, value) => dispatch({ type: "IS_DELETE", value });
const setProfileUrl = (dispatch, value) => dispatch({ type: "PROFILE_URL", value });
const setCRMDataList = (dispatch, value) => dispatch({ type: "CRMDATA_LIST", value });
const setJournalList = (dispatch, value) => dispatch({ type: "JOURNAL_LIST", value });
const setShopList = (dispatch, value) => dispatch({ type: "SHOP_LIST", value });
const setReplyRequest = (dispatch, value) => dispatch({ type: "REPLY_REQUEST", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setLayout,
  setDarkMode,
  setShowLoader,
  setCustomerList,
  setCategoryList,
  setProductList,
  setOrderList,
  setSnackBar,
  setCouponList,
  setEnquiryList,
  setSubcribeList,
  setIsDelete,
  setProfileUrl,
  setCRMDataList,
  setJournalList,
  setShopList,
  setReplyRequest,
};
