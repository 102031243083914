import axios from "../util/axios";

const fetchAllProducts = () => axios.get("products");

const addProduct = (data) => axios.post("products/create", data);

const updateProduct = (data, id) => axios.put("products/" + id, data);

const removeProduct = (id) => axios.delete("products/" + id);

export default {
  fetchAllProducts,
  addProduct,
  updateProduct,
  removeProduct
};
