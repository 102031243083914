import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import authService from "services/auth.service";
import { useMaterialUIController, setShowLoader } from "context";
import { EMAIL_REGEX } from "util/ValidationRegex";
import { USER_AUTH_INFO } from "util/Constants";
import { toast } from "react-toastify";
import { setSnackBar } from "context";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [, dispatch] = useMaterialUIController();

  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const handleLogin = (data) => {
    setShowLoader(dispatch, true);

    const reqBody = { ...data, username: "Admin" };
    authService
      .login(reqBody)
      .then(
        (res) => {
          if (res && res.data) localStorage.setItem(USER_AUTH_INFO, JSON.stringify(res.data));
          history.push("/register#addressTab");
        },
        (error) => {
          setSnackBar(dispatch, { title: "Sign-in Error", content: "Invalid username or password!", color: "error", icon: 'warning', bgWhite: true });

        }
      )
      .then(() => setShowLoader(dispatch, false));
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={3}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 1 }} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                error={errors.email && true}
                success={errors.email && false}
                {...register("email", { required: true, pattern: EMAIL_REGEX })}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                error={errors.password && true}
                success={errors.password && false}
                {...register("password", { required: true })}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={handleSubmit(handleLogin)}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
