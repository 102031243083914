import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setCRMDataList, setSnackBar } from "context";
import categoryService from "services/category.service";
import CustomizedDialogs from "components/MDDialog";
import { Link, useParams } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";
import crmService from "services/crm.service";
import referenceService from "services/reference.service";
import productService from "services/product.service";
import { setProductList } from "context";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { crmData, isDelete, products } = controller;
  const [open, setOpen] = useState(false);
  const { crmid } = useParams();
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getCRMData = () => {
    setShowLoader(dispatch, true);

    productService
      .fetchAllProducts()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setProductList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
    crmService
      .fetchAllCRMData()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setCRMDataList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getCRMData();
  }, [isDelete]);

  const CRM = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Description = ({ description }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <CustomizedDialogs
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        buttonname="description"
        buttontext="close"
        body={description}
        title="Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    </MDBox>
  );

  const Image = ({ image, name }) => (
    // <MDBox ml={-1}>
    <MDAvatar src={image} name={name} size="sm" />

    // </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const fetchedList = () => {
    const list = [];
    // // setShowLoader(dispatch, true);

    referenceService
      .fetchAllOptions()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          if (res && res.data) {
            console.log(res.data)
            res.data.forEach(el => list.push({
              key: el.id,
              value: el.page_name
            }))
          }
          console.log(list)
          return list;

          //  .push("/customers");
        },
        (error) => console.error(error)
      )
    //   .then(() => setShowLoader(dispatch, false));
    console.log(list)
    return list;

  }
  const generateRow = () => {
    const rows = [];
    console.log(crmData)
    crmData?.forEach((el) => {
      rows.push({
        page_name: <CRM name={el.references?.page_name} />,
        description: <Description description={el.description} />,
        image: <Image image={el.images?.path} name={el.images?.original_name} />,
        created: <Created createDate={el.created_at} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/crm/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
            <ActionDialog
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              buttontext="delete"
              buttonname="Delete"
              body={"Are you sure you want to delete this data ?"}
              open={open}
              setOpen={() => setOpenId(el.id)}

              onClick={() => setOpenId(el.id)}
              crmid={selectedId}
              title="Delete Data&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            />
          </MDBox>
        ),
      });
    });
    return rows;
  };

  const generateIndexList = () => {
    const prodIndex = [];
    let tempArr = [];
    if (crmData.length > 0) {

      let tempArr = [...Array(crmData.length).keys()];

      // crmData.forEach(el => {
      //   let index = tempArr.indexOf(el.product_index ? +el.product_index : -1);
      //   if (index >= 0) {
      //     tempArr.splice(index, 1);
      //   }
      // });
      tempArr.forEach(el => prodIndex.push({ key: el + 1, value: el + 1 }));
      // let myArray = prodIndex.filter( ( el ) => el.key.inclues );

      // });
    } else {
      prodIndex.push({ key: crmData.length + 1, value: crmData.length + 1 });
    }
    if (!crmid && crmData.length > 0) {
      prodIndex.push({ key: crmData.length + 1, value: crmData.length + 1 });
    }

    return prodIndex;
  };

  const generateProductList = () => {
    let productList = []
    products.forEach(el => productList.push({ "key": el.id, "value": el.name }))

    return productList;
  }

  return {
    columns: [
      { Header: "page name", accessor: "page_name", width: "35%", align: "left" },
      { Header: "description", accessor: "description", width: "15%", align: "left" },
      { Header: "image/video", accessor: "image", align: "center" },
      { Header: "create date", accessor: "created", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
    options: fetchedList(),
    indexes: generateIndexList(),
    productList: generateProductList(),
  };
};

export default authorsTableData;
