import { useState, useEffect } from "react";

// react-router components
import { useLocation, Redirect, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-white.png";
import brandDark from "assets/images/logo-black.png";
import routes from "router/appRoutes";
import { Backdrop, CircularProgress } from "@mui/material";
import requireLogin from "router/guards/requireLogin";
import MDSnackbar from "components/MDSnackbar";
import { setSnackBar } from "context";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    showLoader,
    snackBar,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <GuardedRoute
            key={route.key}
            exact={route.path === "/"}
            ignoreGlobal={route.ignoreGlobal}
            meta={route.meta}
            path={route.route}
            element={route.component}
          >
            {route.component}
          </GuardedRoute>
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const [infoSB, setInfoSB] = useState(true);
  const openInfoSB = () => setInfoSB(true);

  const closeInfoSB = () => {
    setSnackBar(dispatch, {});
    setInfoSB(false);
  };

  // Setting page snackbar toggle on api call
  useEffect(() => {
    setInfoSB(true);
  }, [showLoader]);
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Backdrop open={showLoader} style={{ zIndex: 10 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {showLoader && openInfoSB}
      {infoSB && Object.keys(snackBar).length > 0 && (
        <>
          <MDSnackbar
            color={snackBar?.color || 'info'}
            icon={snackBar?.icon || 'notification'}
            title={snackBar?.title}
            content={snackBar?.content}
            dateTime="Just Now"
            open={infoSB}
            onClose={closeInfoSB}
            close={closeInfoSB}
            bgWhite={snackBar?.bgWhite || false}
          />
        </>
      )}
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Britacel"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <GuardProvider guards={[requireLogin]}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </GuardProvider>
    </ThemeProvider>
  );
}
