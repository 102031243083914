// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { setShowLoader } from "context";
import orderService from "services/order.service";

function Invoice({ date, id, price, noGutter }) {

  const [controller, dispatch] = useMaterialUIController();

  const { orders } = controller;
  const handlePDFDOwnload = () => {
    setShowLoader(dispatch, true);
    orderService
      .downloadPDFInvoice({ orderid: id })
      .then(
        (res) => {
          if (res && res.data.url) {
            setTimeout(() => {
              window.open(res.data.url, '_blank');
            }, 1000);
          }
          // setOrderList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {date}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          {price}
        </MDTypography>
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }} onClick={() => handlePDFDOwnload()}>
          <Icon fontSize="small">picture_as_pdf</Icon>
          <MDTypography variant="button" fontWeight="bold">
            &nbsp;PDF
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
