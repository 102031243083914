import { useEffect, useState } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, setShowLoader, setCategoryList, setSnackBar } from "context";
import categoryService from "services/category.service";
import CustomizedDialogs from "components/MDDialog";
import { Link } from "react-router-dom";
import ActionDialog from "components/MDDialog/ActionDialog";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { categories, isDelete } = controller;
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const setOpenId = (el = null) => {
    console.log(el)
    setOpen(!open);
    (el != null) ? setSelectedId(el) : '';
  }
  const getCategoryList = () => {
    setShowLoader(dispatch, true);

    categoryService
      .fetchAllCategories()
      .then(
        (res) => {
          // setSnackBar(dispatch, { title: "Notification", content: "Hello, there! Categories list is loaded." });
          setCategoryList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getCategoryList();
  }, [isDelete]);

  const Category = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Description = ({ description }) => (
    <MDBox lineHeight={1} textAlign="left" style={{ whiteSpace: "pre", wordWrap: "break-word" }}>
      <CustomizedDialogs
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        buttonname="description"
        buttontext="close"
        body={description}
        title="Category Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      />
    </MDBox>
  );

  const Status = ({ status }) => (
    <MDBox ml={-1}>
      <MDBadge
        badgeContent={`${status === 1 ? "active" : "inactive"}`}
        color={`${status === 1 ? "success" : "secondary"}`}
        variant="gradient"
        size="sm"
      />
    </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const generateRow = () => {
    const rows = [];
    categories?.forEach((el) => {
      rows.push({
        category: <Category image={el.image?.path} name={el.title} />,
        description: <Description description={el.description} />,
        status: <Status status={el.status} />,
        created: <Created createDate={el.created_at} />,
        action: (
          <MDBox ml={-1}>
            <Link to={`/categories/edit/${el.id}`} >
              <MDBadge
                badgeContent="edit"
                color="info"
                variant="gradient"
                size="sm"
                style={{
                  cursor: "pointer",
                }}
              />
            </Link>
            <ActionDialog
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
            buttontext="delete"
            buttonname="Delete"
            body={"Are you sure you want to delete this category ?"}
            open={open}
            setOpen={() => setOpenId(el.id)}

            onClick={() => setOpenId(el.id)}
            catid={selectedId}
            title="Delete Category&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          />
          </MDBox>
        ),
      });
    });
    return rows;
  };

  const generateCategoryList = () => {
    const cat = [];
    categories.forEach((el) => {
      cat.push({ key: el.id, value: el.title })
    });
    return cat;
  };

  return {
    columns: [
      { Header: "category", accessor: "category", width: "35%", align: "left" },
      { Header: "description", accessor: "description", width: "15%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "create date", accessor: "created", align: "left" },
      { Header: "", accessor: "action", align: "center" },
    ],
    rows: generateRow(),
    options: generateCategoryList(),
  };
};

export default authorsTableData;
