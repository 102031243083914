
// @mui material components
import { Autocomplete, Card, Grid, Icon, Tooltip } from "@mui/material";
import moment from 'moment';

// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Britacel example components
import TimelineItem from "examples/Timeline/TimelineItem";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import '../style.css';
import { useForm } from "react-hook-form";
import { setShowLoader } from "context";
import orderService from "services/order.service";
import { useHistory } from "react-router";



function TrackOrder(props) {
  const { createdt, updatedt, status, oid, dispatch } = props;
  const { watch, setValue } = useForm({
    mode: "onChange",
  });

  const [currStatus, setCurrStatus] = useState({ key: 'pending', value: 'Order Received' });
  const routeHistory = useHistory();

  const getStatus = (key) => {
    let value = "Order Received";
    if (key === 'shipped') {
      value = 'In Transit'
      setCurrStatus({ key: 'shipped', value: 'In Transit' })
    } else if (key === 'finished') {
      value = 'Delivered';
      setCurrStatus({ key: 'finished', value: 'Delivered' })
    } else if (key == 'pending') {
      setCurrStatus({ key: 'pending', value: 'Order Received' })
    }
    return value;
  }
  const defaultProps = {
    options: [{ key: 'pending', value: 'Order Received' },
    { key: 'shipped', value: 'In Transit' },
    { key: 'finished', value: 'Delivered' }],
    getOptionLabel: (option) => option.value,
  };

  const [isClicked, setIsClicked] = useState(true);


  const handleStatus = (event) => {
    setValue("order_status", !isClicked);
    setIsClicked(!isClicked);
  }

  useEffect(() => {
    getStatus(status);
    setValue("order_status", true);
  }, [status]);

  const handleUpdateStatus = (event) => {
    setCurrStatus({ key: event.key, value: getStatus(event.key) });
    setShowLoader(dispatch, true);
    orderService
      .updateStatus({
        "status": event.key,
        "oid": oid
      })
      .then(
        (res) => {
          routeHistory.push("/orders");
          // setOrderList(dispatch, res.data);
          //  .push("/customers");
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  }

  return (
    <Card sx={{
      height: "100%",
      width: {
        sx: 1.0, // 100%
        sm: 320,
        md: 450,
      },
    }}
    >
      <MDBox pt={2} px={5} onClick={(el) => handleStatus(el)}>
        <Grid container spacing={3}>
          <Grid item xs={9} lg={9}>
            <MDTypography variant="h6" fontWeight="medium">
              Order Status
            </MDTypography>
          </Grid>
          <Grid item xs={3} lg={3} style={{ cursor: 'pointer' }}  >
            <div title="click to enable status">
              {isClicked && <Icon fontSize="medium" >lock</Icon>}
            </div>
            <div title="click to disable status">
              {!isClicked && <Icon fontSize="medium" >lock_open</Icon>}
            </div>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox px={5}>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={5.5}>
            <TimelineItem
              className="iconName"
              color="success"
              icon="task_alt"
              title="Order Received"
              dateTime={moment(createdt).format("LLL")}
            />
            <TimelineItem
              className="iconName"
              color={status === "shipped" ? "success" : "warning"}
              icon="directions_transit"
              title="In Trasit"
              dateTime={status === "shipped" ? moment(updatedt).format("LLL") : "-"}
            />
            <TimelineItem
              className="iconName"
              color={status === "finished" ? "success" : "light"}
              icon="shopping_cart"
              title="Delivered"
              dateTime={status === "finished" ? (moment(updatedt).format("LLL")) : "-"}
              lastItem
            />
          </Grid>
          <Grid item xs={6} lg={6.5}>
            <Autocomplete
              {...defaultProps}
              id="order-status"
              value={currStatus}
              onChange={(event, newValue) => {
                handleUpdateStatus(newValue);
              }}
              renderInput={(params) => (
                <MDInput {...params} label="Status" variant="outlined" sx={{ mt: 15, mb: 1 }} disabled={watch("order_status") == true} />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default TrackOrder;
