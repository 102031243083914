import axios from "../util/axios";

const fetchAllCRMData = () => axios.get("crm");

const addCRM = (data) => axios.post("crm/create", data);

const updateCRM = (data, id) => axios.put("crm/" + id, data);

const removeCRM = (id) => axios.delete("crm/" + id);

export default {
  fetchAllCRMData,
  addCRM,
  updateCRM,
  removeCRM,
};
