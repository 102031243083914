import { useEffect } from "react";
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Britacel components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useMaterialUIController, setShowLoader, setSubcribeList, setSnackBar } from "context";
import subscribeService from "services/subscribe.service";

const authorsTableData = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { subscribes } = controller;
  const getSubscribesList = () => {
    setShowLoader(dispatch, true);

    subscribeService
      .fetchAllSubscribes()
      .then(
        (res) => {
          setSubcribeList(dispatch, res.data);
        },
        (error) => console.error(error)
      )
      .then(() => setShowLoader(dispatch, false));
  };

  useEffect(() => {
    getSubscribesList();
  }, []);

  const SubscribeMail = ({ email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {email}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Created = ({ createDate }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {createDate.split(" ")[0]}
    </MDTypography>
  );

  const generateRow = () => {
    const rows = [];
    subscribes.forEach((el) => {
      rows.push({
        id: <SubscribeMail email={el.id} />,
        email: <SubscribeMail email={el.email} />,
        created: <Created createDate={el.created_at} />,
      });
    });
    return rows;
  };


  return {
    columns: [
      { Header: "id", accessor: "id", width: "35%", align: "left" },
      { Header: "email", accessor: "email", width: "35%", align: "left" },
      { Header: "create date", accessor: "created", align: "left" },
    ],
    rows: generateRow(),
  };
};

export default authorsTableData;
